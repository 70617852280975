<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `엘더트리엔 | ${content}` : `엘더트리엔` }}</template>
    </metainfo>
    <router-view></router-view>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
    name: 'App',
    setup () {
        useMeta({
            title: 'ELDERTREE N',
            htmlAttrs: { lang: 'ko', amp: true }
        })
    },
    mounted() {
        this.welcomeLog();
    },
    methods: {
        welcomeLog() {
            let w = 
            ` _    _      _
| |  | |    | |
| |  | | ___| | ___ ___  _ __ ___   ___
| |/\\| |/ _ \\ |/ __/ _ \\| '_ \` _ \\ / _ \\
\\  /\\  /  __/ | (_| (_) | | | | | |  __/
 \\/  \\/ \\___|_|\\___\\___/|_| |_| |_|\\___|

 _____ _     _           _                 _   _   _
|  ___| |   | |         | |               | \\ | | | |
| |__ | | __| | ___ _ __| |_ _ __ ___  ___|  \\| | | |
|  __|| |/ _\` |/ _ \\ '__| __| '__/ _ \\/ _ \\ . \` | | |
| |___| | (_| |  __/ |  | |_| | |  __/  __/ |\\  | |_|
\\____/|_|\\__,_|\\___|_|   \\__|_|  \\___|\\___\\_| \\_/ (_)
`;
            console.log(w);
        }
    },
}
</script>

<style>
</style>
