<template>
<div class="container">
    <TopButton @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')"></TopButton>
    <div class="wrap">
        <div class="wrap-top-padding"></div>
        <div class="title">
            <div class="overflow-hidden"><p class="main-title fadein-ani" data-fade-delay="0">Contact us</p></div>
            <div class="overflow-hidden"><p class="sub-title fadein-ani" data-fade-delay="300">저희와 함께하실래요?</p></div>
            <div class="overflow-hidden"><p class="txt fadein-ani" data-fade-delay="500">엘더트리N과 변화를 가지고 나아갈 당신의 새로운 프로젝트, 경험 이야기를 들려주세요.<br>상세하게 알려주신다면 더 자세한 상담이 가능합니다.</p></div>
        </div>
        <div class="form-tab fadein-opacity-ani" data-fade-delay="800">
            <span class="selection"></span>
            <div class="option" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                <input type="radio" id="project" name="category" value="project">
                <label for="project"><span>프로젝트 문의</span></label>
            </div>
            <div class="option" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                <input type="radio" id="job" name="category" value="job">
                <label for="job"><span>입사 지원</span></label>
            </div>
        </div>
        <div class="forms fadein-opacity-ani" data-fade-delay="1000">
            <form name="projectForm">
                <div class="input-box">
                    <div class="title" id="focusProjectType">1. 어떤 유형의 프로젝트인가요? *</div>
                    <div class="checkbox-container flex-blank">
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-web" name="project-type[]" value="WEB">
                            <label for="project-type-web"><span>Web</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-mobile" name="project-type[]" value="MOBILE">
                            <label for="project-type-mobile"><span>Mobile</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-app" name="project-type[]" value="APP">
                            <label for="project-type-app"><span>APP</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-build" name="project-type[]" value="NEW">
                            <label for="project-type-build"><span>신규/구축</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-renewal" name="project-type[]" value="RENEWAL">
                            <label for="project-type-renewal"><span>리뉴얼</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-manage" name="project-type[]" value="MAINTENANCE">
                            <label for="project-type-manage"><span>유지보수</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="project-type-other" name="project-type[]" value="OTHER">
                            <label for="project-type-other"><span>기타</span></label>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <div class="title" id="focusProjectPlan">2. 프로젝트의 예산과 착수 일정을 알려주세요. *</div>
                    <div class="input-container">
                        <div class="select-box">
                            <p>프로젝트 예산</p>
                            <div class="select-value" id="projectBudget" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                                <span>선택해주세요</span>
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.706233 2.41361L3.29623 5.00361C3.68623 5.39361 4.31623 5.39361 4.70623 5.00361L7.29623 2.41361C7.92623 1.78361 7.47623 0.703613 6.58623 0.703613H1.40623C0.516233 0.703613 0.0762329 1.78361 0.706233 2.41361Z" fill="#232324"/>
                                </svg>
                            </div>
                            <div class="options" data-for="projectBudget" id="budgetOption">
                                <div data-value="10m-50m" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">1천만원 ~ 5천만원</div>
                                <div data-value="50m-100m" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">5천만원 ~ 1억원</div>
                                <div data-value="100m-300m" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">1억원 ~ 3억원</div>
                                <div data-value="300m-500m" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">3억원 ~ 5억원</div>
                                <div data-value="500m-more" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">5억원 이상</div>
                            </div>
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                        <div class="text">
                            <p>프로젝트 일정</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="plan" type="text" placeholder="ex) 착수일 ~ 오픈 예정일 or 3개월 내 오픈" tabIndex="1" maxlength="100">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <div class="title">3. 프로젝트에 대해 참고할 URL이 있다면 알려주세요.</div>
                    <div class="input-container">
                        <div class="text">
                            <p>기존 URL (선택)</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="asis-url" type="text" placeholder="현재 URL을 알려주세요." tabIndex="2" maxlength="100">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                        <div class="text">
                            <p>참고 URL (선택)</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="ref-url" type="text" placeholder="선호하는 레퍼런스나 방향에 참고할 URL이 있다면 알려주세요." tabIndex="3" maxlength="100">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <div class="title">4. 어떤 프로젝트인지 자세하게 알려주세요.</div>
                    <div class="input-container flex-direction-column">
                        <div class="textarea">
                            <p>
                                <span>프로젝트의 목적, 문의 내용 등 (선택)</span>
                                <span class="textarea-count">{{projectDetailCount}}/1000</span>
                            </p>
                            <textarea @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="project-detail" maxlength="1000" placeholder="프로젝트의 명칭, 목적, 서비스 등 자세하게 알려주시면 원활한 상담이 가능합니다." tabIndex="4"></textarea>
                        </div>
                        <div class="file">
                            <p>첨부파일 업로드 (선택)</p>
                            <label class="file-value" for="file" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                                <span>1개의 파일, 50MB이하</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z" fill="#232324"/>
                                </svg>
                            </label>
                            <input type="file" id="file" hidden>
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <div class="title" id="focusClient">5. 당신에 대해 알려주세요. *</div>
                    <div class="input-container">
                        <div class="text">
                            <p>회사명 (담당 부서 포함)</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="company" type="text" placeholder="회사명을 알려주세요." tabIndex="5" maxlength="50">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                        <div class="text">
                            <p>담당자 명 (직책 포함)</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="name" type="text" placeholder="담당자 이름과 직책을 알려주세요." tabIndex="6" maxlength="50">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="input-container mt-32">
                        <div class="text">
                            <p>연락처</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="contect-number" type="text" placeholder="연락 가능한 번호를 알려주세요." tabIndex="7" maxlength="15">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                        <div class="text">
                            <p>이메일</p>
                            <input @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="tabindex" name="email" type="text" placeholder="연락 가능한 이메일을 알려주세요." tabIndex="8" maxlength="255">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="input-container mt-32">
                        <div class="normal-checkbox">
                            <input type="checkbox" id="policy" name="project-policy">
                            <label for="policy" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                                <div class="check-icon">
                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.86331 7.58335L1.54998 5.27002C1.28998 5.01002 0.869978 5.01002 0.609978 5.27002C0.349978 5.53002 0.349978 5.95002 0.609978 6.21002L3.39664 8.99668C3.65664 9.25668 4.07664 9.25668 4.33664 8.99668L11.39 1.94335C11.65 1.68335 11.65 1.26335 11.39 1.00335C11.13 0.74335 10.71 0.74335 10.45 1.00335L3.86331 7.58335Z" fill="white"/>
                                    </svg>                                
                                </div>
                                <span>개인정보보호정책에 동의합니다.</span>
                            </label>
                            <a @click="openTermsPopup" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">약관보기</a>
                        </div>
                    </div>
                    <a class="button full-radius large mt-60 center" @click="submitProjectForm" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                        <span>엘더트리N에 프로젝트 문의하기</span>
                    </a>
                </div>
            </form>
            <form name="jobForm">
                <div class="input-box">
                    <div class="title" id="focusJobType">1. 어떤 분야에 지원하나요? *</div>
                    <div class="checkbox-container">
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="job-type-pm" name="job-type[]" value="PM">
                            <label for="job-type-pm"><span>PM / 유지관리</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="job-type-pl" name="job-type[]" value="PL">
                            <label for="job-type-pl"><span>웹기획자</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="job-type-design" name="job-type[]" value="DESIGN">
                            <label for="job-type-design"><span>디자이너</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="job-type-pub" name="job-type[]" value="PUB">
                            <label for="job-type-pub"><span>퍼블리셔</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="job-type-dev" name="job-type[]" value="DEV">
                            <label for="job-type-dev"><span>개발자</span></label>
                        </div>
                        <div class="checkbox" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <input type="checkbox" id="job-type-other" name="job-type[]" value="OTHER">
                            <label for="job-type-other"><span>기타</span></label>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <div class="title" id="focusJoinClient">2. 당신에 대해 알려주세요. *</div>
                    <div class="input-container">
                        <div class="text">
                            <p>이름</p>
                            <input class="tabindex" type="text" placeholder="이름을 알려주세요." name="name" tabIndex="-1" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" maxlength="11">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                        <div class="select-box">
                            <p>지원분야 경력</p>
                            <div class="select-value" id="career" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                                <span>선택해주세요</span>
                                <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.706233 2.41361L3.29623 5.00361C3.68623 5.39361 4.31623 5.39361 4.70623 5.00361L7.29623 2.41361C7.92623 1.78361 7.47623 0.703613 6.58623 0.703613H1.40623C0.516233 0.703613 0.0762329 1.78361 0.706233 2.41361Z" fill="#232324"/>
                                </svg>
                            </div>
                            <div class="options" data-for="career" id="careerOption">
                                <div data-value="new" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">신입</div>
                                <div data-value="1y-3y" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">1 ~ 3년</div>
                                <div data-value="3y-5y" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">3 ~ 5년</div>
                                <div data-value="5y-10y" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">5 ~ 10년</div>
                                <div data-value="10y-15y" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">10 ~ 15년</div>
                                <div data-value="15y-more" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">15년 이상</div>
                            </div>
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="input-container mt-32">
                        <div class="text">
                            <p>연락처</p>
                            <input class="tabindex" type="text" placeholder="연락 가능한 번호를 알려주세요." name="phone" tabIndex="-1" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" maxlength="15">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                        <div class="text">
                            <p>이메일</p>
                            <input class="tabindex" type="text" placeholder="연락 가능한 이메일을 알려주세요." name="email" tabIndex="-1" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" maxlength="255">
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-box">
                    <div class="title" id="focusResume">3. 더 자세하게 알려줄 수 있나요?</div>
                    <div class="input-container flex-direction-column">
                        <div class="textarea">
                            <p>
                                <span>자기소개, 포트폴리오 URL 등 (선택)</span>
                                <span class="textarea-count">{{jobDetailCount}}/1000</span>
                            </p>
                            <textarea class="tabindex" name="job-detail" maxlength="1000" placeholder="위의 공간에 나를 표현하기엔 모자라지 않나요?&#10;자기소개나 포트폴리오 URL 등.. 더 자세하게 알려주세요." tabIndex="-1" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')"></textarea>
                        </div>
                        <div class="file">
                            <p>이력서 첨부는 필수입니다. (최대 50MB)</p>
                            <label class="file-value" for="file-portfolio" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                                <span>1개의 파일, 50MB이하</span>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 11V14H2V11H0V14C0 15.1 0.9 16 2 16H14C15.1 16 16 15.1 16 14V11H14ZM3 5L4.41 6.41L7 3.83V12H9V3.83L11.59 6.41L13 5L8 0L3 5Z" fill="#232324"/>
                                </svg>
                            </label>
                            <input type="file" name="portfolio" id="file-portfolio" hidden>
                            <div class="border">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div class="input-container mt-32">
                        <div class="normal-checkbox">
                            <input type="checkbox" id="join-policy" name="join-policy">
                            <label for="join-policy" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                                <div class="check-icon">
                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.86331 7.58335L1.54998 5.27002C1.28998 5.01002 0.869978 5.01002 0.609978 5.27002C0.349978 5.53002 0.349978 5.95002 0.609978 6.21002L3.39664 8.99668C3.65664 9.25668 4.07664 9.25668 4.33664 8.99668L11.39 1.94335C11.65 1.68335 11.65 1.26335 11.39 1.00335C11.13 0.74335 10.71 0.74335 10.45 1.00335L3.86331 7.58335Z" fill="white"/>
                                    </svg>                                
                                </div>
                                <span>개인정보보호정책에 동의합니다.</span>
                            </label>
                            <a @click="openTermsPopup" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">약관보기</a>
                        </div>
                    </div>
                    <a class="button full-radius large mt-60 center" @click="submitJobForm" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                        <span>엘더트리N에 입사 지원하기</span>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>  
</template>

<script>
import axios from 'axios';
import TopButton from './common/TopButton.vue';
import { useMeta } from 'vue-meta';

export default {
    name: 'ContactUs',
    props: {
        isLoading : Boolean,
    },
    data() {
        return {
            pageStartTime: 0,
            timeouts: [], // setTimeout을 사용할 땐 이 변수에 ID를 넣어야한다.
            projectDetailCount: 0,
            jobDetailCount: 0,
            isSubmit: false,
        }
    },
    components: {
        TopButton: TopButton,
    },
    setup() {
        useMeta({ title: 'Contact Us' });
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        document.body.classList.remove('white');
        this.resetPage();
        if(!this.isLoading) {
            this.pageStartTime = 750; // ms
        }
        this.timeouts.push(setTimeout(() => {
            this.resetContactUs();
        }, this.pageStartTime));

        if(this.$route.query.type == 'join') {
            document.getElementById('job').checked = true;
            this.updatePillPosition();
        }
    },
    beforeMount() {
        this.$emit('hideMask');
    },
    beforeUnmount() {
        this.resetPage();
        this.clearTimeouts();
    },
    methods: {
        clearTimeouts() {
            this.timeouts.forEach((timeoutId) => {
                clearTimeout(timeoutId);
            });
        },
        resetPage() {
            // INPUT 초기화
            document.projectForm.reset();
            document.jobForm.reset();
            this.resetFile();
            // fadein animation
            document.querySelectorAll('.fadein-ani').forEach((el) => {
                el.style.transition = 'transform 0s';
                el.style.transform = `translateY(100%)`;
            });
            // fadein animation (opacity)
            document.querySelectorAll('.fadein-opacity-ani').forEach((el) => {
                el.style.transition = 'transform 0s';
                el.style.transform = `translateY(50px)`;
                el.style.opacity = '0';
            });
        },
        /* --- contact us form tab */
        // Main
        // Body functions
        setup() {
            this.resetContactUs();
        },
        resetContactUs() {
            this.forEachElement(".form-tab", (elem) => {
                elem.addEventListener("change", this.updatePillPosition);
            });
            window.addEventListener("resize", this.updatePillPosition); // Prevent pill from detaching from element when window resized. Becuase this is rare I haven't bothered with throttling the event

            // fadein animation
            document.querySelectorAll('.fadein-ani').forEach((el) => {
                this.timeouts.push(setTimeout(() => {
                    el.style.transition = 'transform 1s';
                    el.style.transform = `translateY(0)`;
                }, el.dataset.fadeDelay));
            });
            // fadein animation (opacity)
            document.querySelectorAll('.fadein-opacity-ani').forEach((el) => {
                this.timeouts.push(setTimeout(() => {
                    el.style.transition = 'transform 1s , opacity 1s';
                    el.style.transform = `translateY(0)`;
                    el.style.opacity = '1';
                }, el.dataset.fadeDelay));
            });
            /* --- contact us form tab */
            
            // input select event
            document.querySelectorAll('.select-box').forEach((selectBox) => {
                selectBox.addEventListener('click', () => {
                    let optionsElement = selectBox.getElementsByClassName('options')[0];
                    // selectBox.
                    if(optionsElement.classList.contains('show')) {
                        optionsElement.classList.remove('show');
                    } else {
                        optionsElement.classList.add('show');
                    }
                });
            });
            // input select option click event
            document.querySelectorAll('.options').forEach((selectElement) => {
                selectElement.addEventListener('click', (e) => {
                    e.stopPropagation();
                    let parentOptions = e.target.parentNode;
                    // option 창이 꺼져있으면 실행하지 않음
                    if(!parentOptions.classList.contains('show')) {
                        return;
                    }

                    this.removeChooseSelect();
                    e.target.classList.add('select-choose');
                    // 보여지는 텍스트 교체
                    document.querySelector(`#${parentOptions.dataset.for} span`).textContent = e.target.textContent;
                    document.querySelector(`#${parentOptions.dataset.for} span`).classList.add('selected');
                    // options close
                    parentOptions.classList.remove('show');
                });
            });
            // project-detail
            document.projectForm['project-detail'].addEventListener('keydown', (e) => {
                let text = e.target.value;
                text = e.target.value;
                this.projectDetailCount = text.length;
            });
            document.jobForm['job-detail'].addEventListener('keydown', (e) => {
                let text = e.target.value;
                this.jobDetailCount = text.length;
            }); 
            // input file event
            document.querySelectorAll('input[type=file]').forEach((fileInput) => {
                fileInput.addEventListener('change', (e) => {
                    let file = fileInput.files[0];
                    let fileTitle = document.querySelector(`label[for='${e.target.id}'] span`);

                    if(this.fileValidate(file)) {
                        fileTitle.textContent = file.name;
                        fileTitle.classList.add('selected');
                    } else {
                        // 파일 초기화
                        e.target.value = '';
                        fileTitle.textContent = '1개의 파일, 50MB이하';
                        fileTitle.classList.remove('selected');
                    }
                });
            });
        },
        resetFile() {
            document.querySelectorAll('input[type=file]').forEach((fileInput) => {
                let fileTitle = document.querySelector(`label[for='${fileInput.id}'] span`);
                fileInput.value = '';
                fileTitle.textContent = '1개의 파일, 50MB이하';
                fileTitle.classList.remove('selected');
            });
        },
        updatePillPosition() {
            this.forEachElement(
                ".form-tab .option input",
                (elem, index) => {
                    if (elem.checked) this.moveBackgroundPillToElement(elem, index);
                }
            );
        },
        moveBackgroundPillToElement(elem, index) {
            document.querySelector(
                ".form-tab .selection"
            ).style.transform = "translateX(" + ((elem.offsetWidth * index)+(8 * index)) + "px)";

            // change tab
            document.projectForm.classList.remove('show');
            document.jobForm.classList.remove('show');
            document.projectForm.classList.remove('hide');
            document.jobForm.classList.remove('hide');
            // TAB INDEXINGS
            let jobTabindexs = document.jobForm.getElementsByClassName('tabindex');
            let projectTabindexs = document.projectForm.getElementsByClassName('tabindex');

            if(elem.value == 'project') {
                document.jobForm.classList.add('hide');
                document.projectForm.classList.add('show');
                // TAB INDEX 재정의
                for(let i = 0; i < jobTabindexs.length; i++) {
                    jobTabindexs[i].tabIndex = -1;
                }
                for(let i = 0; i < projectTabindexs.length; i++) {
                    projectTabindexs[i].tabIndex = i+1;
                }
            } else if(elem.value == 'job') {
                document.projectForm.classList.add('hide');
                document.jobForm.classList.add('show');
                // TAB INDEX 재정의
                for(let i = 0; i < jobTabindexs.length; i++) {
                    jobTabindexs[i].tabIndex = i+1;
                }
                for(let i = 0; i < projectTabindexs.length; i++) {
                    projectTabindexs[i].tabIndex = -1;
                }
            }
        },
        // Helper functions
        forEachElement(className, fn) {
            Array.from(document.querySelectorAll(className)).forEach(fn);
        },
        // 선택된 select 해제
        removeChooseSelect() {
            document.querySelectorAll('.select-choose').forEach((el) => {
                el.classList.remove('select-choose');
            });
        },
        // file validate
        fileValidate(file) {
            let limitBytes = 50 * 1024 * 1024;

            // 빈 파일 체크
            if(!file) {
                return false;
            }
            // 파일 크기 제한
            if(file.size > limitBytes) {
                let options = {
                    message: '첨부파일은 최대 50MB를 넘을 수 없어요.<br/>다시 확인 후 업로드해주세요 :)',
                };
                this.$emit("openAlert", options);
                return false;
            }

            return true;
        },
        openTermsPopup() {
            this.$emit("openTermsPopup");
        },
        submitProjectForm() {
            if(this.isSubmit) {
                return;
            }
            this.isSubmit = true;
            let form = document.projectForm;
            // project type validation
            let types = '';
            form['project-type[]'].forEach((projectType) => {
                if(projectType.checked) {
                    types += projectType.value+"|";
                }
            });
            if(types.length <= 0) {
                let options = {
                    message: '어떤 유형의 프로젝트인가요?',
                    btnFunction: () => {
                        let padding = 200;
                        let focusElement = document.getElementById('focusProjectType');
                        let elPosY = Math.floor(focusElement.getBoundingClientRect().top - padding + window.pageYOffset); // document상 element의 좌표 Y값
                        window.scroll({
                            behavior: 'smooth',
                            left: 0,
                            top: elPosY
                        });
                    },
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            types = types.slice(0, -1);
            // project plan validation
            let budgetOptions = document.getElementById("budgetOption");
            let selectedOption = budgetOptions.getElementsByClassName("select-choose")[0];
            let projectPlan = form.plan;
            if(selectedOption == undefined || projectPlan.value.length <= 0) {
                let options = {
                    message: '프로젝트 예산과 일정을 알려주세요.',
                    btnFunction: () => {
                        let padding = 200;
                        let focusElement = document.getElementById('focusProjectPlan');
                        let elPosY = Math.floor(focusElement.getBoundingClientRect().top - padding + window.pageYOffset); // document상 element의 좌표 Y값
                        window.scroll({
                            behavior: 'smooth',
                            left: 0,
                            top: elPosY
                        });
                    },
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            // 담당자 validation
            let company = form.company.value;
            let name = form.name.value;
            let number = form['contect-number'].value;
            let email = form.email.value;
            if(company == '' || name == '' || number == '' || email == '') {
                let options = {
                    message: '당신에 대해 조금 더 알려주세요.',
                    btnFunction: () => {
                        let padding = 200;
                        let focusElement = document.getElementById('focusClient');
                        let elPosY = Math.floor(focusElement.getBoundingClientRect().top - padding + window.pageYOffset); // document상 element의 좌표 Y값
                        window.scroll({
                            behavior: 'smooth',
                            left: 0,
                            top: elPosY
                        });
                    },
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            // 개인정보보호정책 validation
            let policyChecked = form['project-policy'].checked;
            if(!policyChecked) {
                let options = {
                    message: '약관동의가 필요해요.',
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            let formData = new FormData();
            formData.append('types', types);
            formData.append('budget', selectedOption.dataset.value);
            formData.append('plan', projectPlan.value);
            formData.append('asisUrl', form['asis-url'].value);
            formData.append('refUrl', form['ref-url'].value);
            formData.append('detail', form['project-detail'].value.replace(/(?:\r\n|\r|\n)/g, '<br />'));
            formData.append('file', document.getElementById("file").files[0]);
            formData.append('company', company);
            formData.append('name', name);
            formData.append('number', number);
            formData.append('email', email);
            axios.post("http://eldertreen.com/manage/api/contact/project.php", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response.data);
                if(response.data[0].result == 'SUCCESS') {
                    let options = {
                        message: '프로젝트 문의가 완료되었습니다.<br/>메일 또는 연락처로 답변드리겠습니다.',
                        btnFunction: () => {
                            location.replace('/contact-us');
                        },
                        btnText: '감사합니다 :)',
                    };
                    this.$emit("openAlert", options);
                } else {
                    let options = {
                        message: '프로젝트 문의 등록을 실패했습니다.<br/>잠시 후 다시시도 해주세요.',
                        btnFunction: () => {
                            location.replace('/contact-us');
                        },
                    };
                    this.$emit("openAlert", options);
                }
            })
            .catch((error) => {
                console.log(error);
                let options = {
                    message: '프로젝트 문의 등록을 실패했습니다.<br/>잠시 후 다시시도 해주세요.',
                    btnFunction: () => {
                        location.replace('/contact-us');
                    },
                };
                this.$emit("openAlert", options);
            })   
        },
        submitJobForm() {
            if(this.isSubmit) {
                return;
            }
            this.isSubmit = true;
            let form = document.jobForm;
            // type validation
            let types = '';
            form['job-type[]'].forEach((projectType) => {
                if(projectType.checked) {
                    types += projectType.value+"|";
                }
            });
            if(types.length <= 0) {
                let options = {
                    message: '어떤 분야에 지원하나요?',
                    btnFunction: () => {
                        let padding = 200;
                        let focusElement = document.getElementById('focusJobType');
                        let elPosY = Math.floor(focusElement.getBoundingClientRect().top - padding + window.pageYOffset); // document상 element의 좌표 Y값
                        window.scroll({
                            behavior: 'smooth',
                            left: 0,
                            top: elPosY
                        });
                    },
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            types = types.slice(0, -1);
            // client validation
            let careerOption = document.getElementById("careerOption");
            let selectedOption = careerOption.getElementsByClassName("select-choose")[0];
            let joinName = form.name;
            let joinPhone = form.phone;
            let joinEmail = form.email;
            if(selectedOption == undefined || joinName.value.length <= 0 ||
               joinPhone.value.length <= 0 || joinEmail.value.length <= 0) {
                let options = {
                    message: '당신에 대해 알려주세요.',
                    btnFunction: () => {
                        let padding = 200;
                        let focusElement = document.getElementById('focusJoinClient');
                        let elPosY = Math.floor(focusElement.getBoundingClientRect().top - padding + window.pageYOffset); // document상 element의 좌표 Y값
                        window.scroll({
                            behavior: 'smooth',
                            left: 0,
                            top: elPosY
                        });
                    },
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            // 담당자 validation
            if(form.portfolio.files[0] == undefined) {
                let options = {
                    message: '이력서를 첨부해주세요.',
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            // 개인정보보호정책 validation
            let policyChecked = form['join-policy'].checked;
            if(!policyChecked) {
                let options = {
                    message: '약관동의가 필요해요.',
                };
                this.$emit("openAlert", options);
                this.isSubmit = false;
                return;
            }
            let formData = new FormData();
            formData.append('types', types);
            formData.append('name', joinName.value);
            formData.append('career', selectedOption.dataset.value);
            formData.append('phone', joinPhone.value);
            formData.append('email', joinEmail.value);
            formData.append('job-detail', form['job-detail'].value.replace(/(?:\r\n|\r|\n)/g, '<br />'));
            formData.append('file', document.getElementById("file-portfolio").files[0]);
            axios.post("http://eldertreen.com/manage/api/contact/join.php", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                if(response.data[0].result == 'SUCCESS') {
                    let options = {
                        message: '입사지원 완료되었습니다.<br/>메일 또는 연락처로 답변드리겠습니다.',
                        btnFunction: () => {
                            location.replace('/contact-us');
                        },
                        btnText: '감사합니다 :)',
                    };
                    this.$emit("openAlert", options);
                } else {
                    console.log(response.data[0].message);
                    let options = {
                        message: '입사지원을 실패했습니다.<br/>잠시 후 다시시도 해주세요.',
                        btnFunction: () => {
                            location.replace('/contact-us');
                        },
                    };
                    this.$emit("openAlert", options);
                }
            })
            .catch((error) => {
                console.log(error);
                let options = {
                    message: '입사지원을 실패했습니다.<br/>잠시 후 다시시도 해주세요.',
                    btnFunction: () => {
                        location.replace('/contact-us');
                    },
                };
                this.$emit("openAlert", options);
            })   
        }
    },
    emits: ['hideMask', 'openTermsPopup', 'openAlert', 'cursorOver', 'cursorOut'],
}
</script>

<style>

</style>