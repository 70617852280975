<template>
<footer class="footer">
    <div class="footer-contents">
        <div class="company">
            <div>엘더트리 N 서울특별시 마포구 월드컵로10길 48, 302호</div>
            <div>02.322.7776</div>
            <div>eldern@eldertreen.com</div>
            <div class="copyright">COPYRIGHT 2022 ELDER TREE N. ALL RIGHTS RESERVED.</div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
    name: 'TheFooter',
}
</script>

<style>
/* footer */
.footer {
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #1A1A1B;
  z-index: 550;
  padding-top:40px;
  padding-bottom:40px;
}

.footer .footer-contents .company div {
  font-size: 1.4rem;
  color: #C5C7C9;
  text-align: center;
}
.footer .footer-contents .company div:nth-child(2) {
  margin-top:8px;
}
.footer .footer-contents .company div:nth-child(3) {
  letter-spacing: 0.1rem;
  margin-bottom:8px;
}
.footer .footer-contents .company .copyright {
  font-size: 1.2rem;
  color: #606365;
}

.footer .footer-contents a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
}

.footer .footer-contents a span {
  font-size: 1.4rem;
  color: #FFFFFF;
}
@media (max-width: 768px) {
  /* common */
  .footer .footer-contents {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer .footer-contents a {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 24px;
  }
  .footer .footer-contents .flex-space {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .footer .footer-contents .company {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}
</style>