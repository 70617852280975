import { createRouter, createWebHistory } from "vue-router";
import Error from './components/common/error/Error.vue';
import Main from './components/layout/MainLayout.vue';
import OurWork from './components/OurWork.vue';
import About from './components/About.vue';
import ContactUs from './components/ContactUs.vue';

const routes = [
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "",
        component: OurWork ,
      },
    ]
  },
  {
    path: "/about",
    component: Main,
    children: [
      {
        path: "",
        component: About,
      },
    ]
  },
  {
    path: "/contact-us",
    component: Main,
    children: [
      {
        path: "",
        component: ContactUs,
      },
    ]
  },
  {
    path: "/:anything(.*)",
    component: Error,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;