<template>
<div class="header-wrap">
    <Transition :name="menuTransitionName">
        <div class="menu" v-if="isShowMenu == true">
            <div class="company-info">
                <table>
                    <tr>
                        <td>주소</td>
                        <td>서울특별시 마포구 월드컵로10길 48, 302호</td>
                    </tr>
                    <tr>
                        <td>전화</td>
                        <td>02 322 7776</td>
                    </tr>
                    <tr>
                        <td>팩스</td>
                        <td>02 332 7220</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>eldern@eldertreen.com</td>
                    </tr>
                </table>
            </div>
            <div class="menu-link">
                <div>
                    <div class="text-box" @mouseenter="this.$emit('cursorOver')" @mouseleave="this.$emit('cursorOut')" @click="this.$emit('cursorOut')">
                        <a href="/about" @click="movePage('/about', 'About', $event);">About</a>
                        <div class="link-line"></div>
                    </div>
                    <div class="flex-space"></div>
                </div>
                <div>
                    <div class="text-box" @mouseenter="this.$emit('cursorOver')" @mouseleave="this.$emit('cursorOut')" @click="this.$emit('cursorOut')">
                        <a href="/" @click="movePage('/', 'Our Work', $event);">Our Work</a>
                        <div class="link-line"></div>
                    </div>
                    <div class="flex-space"></div>
                </div>
                <div>
                    <div class="text-box" @mouseenter="this.$emit('cursorOver')" @mouseleave="this.$emit('cursorOut')" @click="this.$emit('cursorOut')">
                        <a href="/contact-us" @click="movePage('/contact-us', 'Contact us', $event)">Contact us</a>
                        <div class="link-line"></div>
                    </div>
                    <div class="flex-space"></div>
                </div>
            </div>
        </div>
    </Transition>
    <header class="header">
        <div class="header-container">
            <div class="logo" @click="movePage('/', 'Our Work', $event);" @mouseenter="this.$emit('cursorOver')" @mouseleave="this.$emit('cursorOut')">
                <svg width="116" height="16" viewBox="0 0 116 16" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_93_2473)" class="logo-dark">
                    <path d="M99.5645 16C100.437 16 101.145 15.2837 101.145 14.4C101.145 13.5164 100.437 12.8 99.5645 12.8C98.6917 12.8 97.9841 13.5164 97.9841 14.4C97.9841 15.2837 98.6917 16 99.5645 16Z"/>
                    <path d="M113.788 0H110.627V8L107.467 0H104.306V14.4C104.306 15.28 103.595 16 102.725 16H104.306H107.467V8L110.627 16H113.788V1.6C113.788 0.72 114.499 0 115.368 0H113.788Z"/>
                    <path d="M0 0V3.2V6.4V9.6V12.8V16H3.16078H6.32156V12.8H3.16078V9.6H6.32156V6.4H3.16078V3.2H6.32156V0H3.16078H0Z"/>
                    <path d="M37.9293 0H31.6078V3.2H37.9293V0Z"/>
                    <path d="M37.9293 6.3999H31.6078V9.5999H37.9293V6.3999Z"/>
                    <path d="M37.9293 12.8H31.6078V16H37.9293V12.8Z"/>
                    <path d="M85.341 0H79.0195V3.2H85.341V0Z"/>
                    <path d="M85.341 6.3999H79.0195V9.5999H85.341V6.3999Z"/>
                    <path d="M85.341 12.8H79.0195V16H85.341V12.8Z"/>
                    <path d="M94.8233 0H88.5018V3.2H94.8233V0Z"/>
                    <path d="M94.8233 6.3999H88.5018V9.5999H94.8233V6.3999Z"/>
                    <path d="M94.8233 12.8H88.5018V16H94.8233V12.8Z"/>
                    <path d="M12.6431 0H9.48236V12.8V16H12.6431H15.8039V12.8H12.6431V0Z"/>
                    <path d="M56.894 0H53.7332V3.2H56.894V16H60.0548V3.2H63.2156V0H60.0548H56.894Z"/>
                    <path d="M45.8313 0H44.2509H41.0901V3.2V6.4V9.6V16H44.2509V9.6H45.8313C48.4389 9.6 50.5724 7.44 50.5724 4.8C50.5724 2.16 48.4389 0 45.8313 0ZM45.8313 6.4C44.962 6.4 44.2509 5.68 44.2509 4.8C44.2509 3.92 44.962 3.2 45.8313 3.2C46.7005 3.2 47.4116 3.92 47.4116 4.8C47.4116 5.68 46.7005 6.4 45.8313 6.4Z"/>
                    <path d="M25.2862 0H22.1254H18.9647V3.2V12.8V16H22.1254H25.2862C27.0246 16 28.447 14.56 28.447 12.8V3.2C28.447 1.44 27.0246 0 25.2862 0ZM25.2862 12.8H22.1254V3.2H25.2862V12.8Z"/>
                    <path d="M47.4117 9.6001V12.8001V16.0001H50.5725V12.8001C50.5725 11.0401 49.1501 9.6001 47.4117 9.6001Z"/>
                    <path d="M71.1175 0H69.5371H66.3763V3.2V6.4V9.6V16H69.5371V9.6H71.1175C73.7252 9.6 75.8587 7.44 75.8587 4.8C75.8587 2.16 73.7252 0 71.1175 0ZM71.1175 6.4C70.2483 6.4 69.5371 5.68 69.5371 4.8C69.5371 3.92 70.2483 3.2 71.1175 3.2C71.9867 3.2 72.6979 3.92 72.6979 4.8C72.6979 5.68 71.9867 6.4 71.1175 6.4Z"/>
                    <path d="M72.6979 9.6001V12.8001V16.0001H75.8587V12.8001C75.8587 11.0401 74.4363 9.6001 72.6979 9.6001Z"/>
                </g>
                <defs>
                    <clipPath id="clip0_93_2473">
                        <rect width="115.368" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            </div>
            <div class="flex-space"></div>
            <div @click="clickMenu" class="burger corsur-pointer" @mouseleave="this.$emit('cursorOut')">
                <div class="strip burger-strip-2">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </header>
</div>
</template>

<script>
export default {
    name: 'TheHeader',
    data() {
        return {
            isShowMenu: false,
            menuTransitionName: 'menu',
        }
    },
    mounted() {
      document.querySelectorAll('a, .corsur-pointer').forEach((e) => {
          e.addEventListener('mouseenter', () => {
            document.querySelector('.cursor').classList.add("type-pointer");
          });
          e.addEventListener('mouseleave', () => {
              document.querySelector('.cursor').classList.remove("type-pointer");
          });
      });
    },
    methods: {
        movePage(path, title, target) {
            target.currentTarget.href = '#';
            let pageData = {
                path: path,
                title: title,
                param: '',
            };
            
            this.$emit('movePage', pageData);
        },
        clickMenu() {
            this.$emit('cursorOut');
            this.isShowMenu = !this.isShowMenu;
            if(this.isShowMenu) {
                document.querySelector('.burger').classList.add('burger-cancel');
                document.querySelector('.header-wrap').classList.add('show-menu');
            } else {
                document.querySelector('.burger').classList.remove('burger-cancel');
                document.querySelector('.header-wrap.show-menu').classList.remove('show-menu');
            }
        },
    },
    emits: ['movePage', 'cursorOver', 'cursorOut'],
}
</script>

<style>/* menu */
.menu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 120px;
  background-color: white;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  z-index: 1000;
}

.menu.show-menu {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.menu .company-info {
  margin-left: -27px;
}

.menu .company-info table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.menu .company-info table tr td {
  color: #606365;
  font-size: 1.6rem;
  letter-spacing: 0;
}

.menu .company-info table tr td:nth-child(1) {
  min-width: 70px;
  font-weight: 700;
}

.menu .company-info table tr td:nth-child(2) {
  min-width: 95px;
}

.menu-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  gap: 52px;
}

.menu-link > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-link div:nth-child(2) a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.menu-link div .link-line {
  height: 2px;
  width: 0%;
  background-color: #1A1A1B;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}

.menu-link div .class-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.menu-link div a {
  font-family: "Cormorant Garamond", serif;
  font-size: 13.6rem;
  line-height: 100%;
}

.menu-link div a:hover + .link-line {
  width: 100%;
}

.menu-link div a p {
  font-size: 2.8rem;
  font-weight: 700;
}

/* header, hamburger*/
.header {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 36px 48px;
  z-index: 1100;
  pointer-events: none;
}

.header .header-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.header .header-container .logo {
  line-height: 100%;
  pointer-events: auto;
}

.header .flex-space {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.header .burger {
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-left: 30px;
  pointer-events: auto; 
}

.header .strip div {
  height: 4px;
  border-radius: 0px;
  background: rgb(35, 35, 36);
  margin: 8px;
  -webkit-transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 40px;
}

.header .burger.burger-cancel .strip div {
  background: rgb(35, 35, 36);
} /* BURGER 2 */
.header .burger.burger-cancel .burger-strip-2 div:first-child {
  -webkit-transform: translateY(12px) rotate(45deg);
          transform: translateY(12px) rotate(45deg);
}

.header .burger.burger-cancel .burger-strip-2 div:nth-child(2) {
  opacity: 0;
}

.header .burger.burger-cancel .burger-strip-2 div:last-child {
  -webkit-transform: translateY(-12px) rotate(-45deg);
          transform: translateY(-12px) rotate(-45deg);
}

.header.filter-none {
  background: none;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}

/* white mode - header */
.white .header .strip div {
  background-color: #FFFFFF;
}

.white .header .logo svg g {
  fill: #FFFFFF;
  -webkit-transition: fill 0.5s;
  transition: fill 0.5s;
}

.white .header-wrap.show-menu .menu + .header .logo svg g {
  fill: #1A1A1B;
}
/* menu 모션 */
.menu-enter-from {transform: translateX(100%);}
.menu-enter-active {transition: transform .5s;}
.menu-enter-to {transform: translateX(0);}
.menu-leave-from {transform: translateX(0%);}
.menu-leave-active {transition: transform .5s;}
.menu-leave-to {transform: translateX(100%);}

@media (max-width: 1248px) {
  .menu .company-info {
    display: none;
  }
}
@media (max-width: 768px) {
  /* common */
  .header {
    padding: 12px 15px 12px 24px;
  }
  .header .strip div {
    margin: 5px;
    width: 30px;
  }
  .header .burger.burger-cancel .burger-strip-2 div:first-child {
    -webkit-transform: translateY(8px) rotate(45deg);
            transform: translateY(8px) rotate(45deg);
  }
  .header .burger.burger-cancel .burger-strip-2 div:last-child {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }
  .menu .menu-link a {
    font-size: 6.4rem;
  }
  .menu .menu-link a p {
    font-size: 1.4rem;
  }
}
</style>