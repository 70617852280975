<template>
<FirstLoading v-if="isLoading == true"></FirstLoading>
<div class="container">
    <TopButton @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')"></TopButton>
    <div class="scroll-text fadein-opacity-ani" data-fade-delay="1000">
        <div>CREATION THAT GIVE EMOTION ON YOUR MIND&nbsp;</div>
        <div>CREATION THAT GIVE EMOTION ON YOUR MIND&nbsp;</div>
    </div>
    <div class="wrap">
        <div class="lines">
            <Transition name="line">
                <div class="line line-first" v-if="isShowLine1"></div>
            </Transition>
            <Transition name="line">
                <div class="line line-second" v-if="isShowLine2"></div>
            </Transition>
            <Transition name="line">
                <div class="line line-three" v-if="isShowLine3"></div>
            </Transition>
        </div>
        <div class="wrap-top-padding"></div>
        <div class="title">
            <div class="overflow-hidden"><p class="main-title fadein-ani" data-fade-delay="300">Our Work</p></div>
            <div class="overflow-hidden"><p class="txt fadein-ani" data-fade-delay="600">사용자 경험을 극대화하고 사용하기 용이한 UI/UX 환경을 제공하기 위해 연구하고 있으며 국내의 대기업, 금융사 및 공공기관 등의 프로젝트를 성공적으로 구축하였습니다.</p></div>
            <a @click="movePage('/contact-us', 'Contact us')" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')" class="work-btn remove-w948 fadein-opacity-ani" data-fade-delay="1000">
                <p>Contact us<br/>프로젝트 문의하기</p>
                <div class="work-btn-mask"></div>
            </a>
        </div>
        <div class="works">
            <Transition name="line-horizontal">
                <div class="line-horizontal" v-if="isShowLineHorizontal"></div>
            </Transition>
            <div class="work" v-for="work in works" :key="work" :class="work.size">
                <div class="work-dim" :style="'background-color:'+work.dim_color"></div>
                <div class="work-container">
                    <div class="corver">
                        <div class="inner-info">
                            <div class="work-title">
                                <div class="overflow-hidden">
                                    <div class="work-type">
                                        <span v-for="type in work.type_codes" :key="type">{{type}}</span>
                                    </div>
                                </div>
                                <div class="overflow-hidden">
                                    <div class="work-title-txt">{{work.title}}</div>
                                </div>
                            </div>
                            <div class="work-info">
                                <table>
                                    <colgroup>
                                        <col width="80px">
                                        <col>
                                    </colgroup>
                                    <tr v-if="work.client_name != ''">
                                        <td>Client</td>
                                        <td>{{work.client_name}}</td>
                                    </tr>
                                    <tr v-if="work.client_period != ''">
                                        <td>Period</td>
                                        <td>{{work.client_period}}</td>
                                    </tr>
                                    <tr v-if="work.detail != ''">
                                        <td>Detail</td>
                                        <td>{{work.detail}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="work-blur"></div>
                        <div class="work-bg">
                            <img :src="'https://eldertreen.cdn1.cafe24.com/thumbnail/'+work.file_name" :alt="work.client_name">
                        </div>
                    </div>
                </div>
            </div>
            <div class="work last" :class="layoutLast[lastIndex]">
                <div class="work-last">
                    <p>We build beautiful &amp; easy-to-manage websites<br/>that bring your team autonomy and new<br/>customers.</p>
                    <a class="last-top-btn" @click="scrollTop" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 3.83V15.5H7.5V3.83V2.62442L6.64676 3.47613L1.41032 8.70321L0.707107 8L8 0.707107L15.2929 8L14.5803 8.71258L9.35387 3.47676L8.5 2.62136V3.83Z" fill="black" stroke="#1A1A1B"/>
                        </svg>                
                    </a>
                </div>
            </div>
            <div class="clear-both"></div>
        </div>
    </div>
</div>
</template>

<script>
import FirstLoading from './common/FirstLoading.vue';
import TopButton from './common/TopButton.vue';
import axios from 'axios';
// import { useMeta } from 'vue-meta';

export default {
    name: 'OurWork',
    props: {
        isLoading : Boolean,
    },
    data() {
        return {
            showDelay: 0,
            isShowLoading: true,
            pageStartTime: 4450,
            timeouts: [], // setTimeout을 사용할 땐 이 변수에 ID를 넣어야한다.
            works: [],
            layoutFirst: ['', 's',   's','m',          'm'],
            layoutLoop:  ['m','m',   's','align-right','s'],
            layoutLast:  ['m','hide','m','s',          'hide'],
            lastIndex: 0,
            isShowLine1: false,
            isShowLine2: false,
            isShowLine3: false,
            isShowLineHorizontal: false,
        }
    },
    // setup() {
    //     useMeta({ title: 'Our Work' });
    // },
    created() {
        this.fetchData();
    },
    mounted() {
        window.scroll({
            left: 0,
            top: document.body.offsetTop
        });
        document.body.classList.remove('white');
        if(!this.isLoading) {
            this.pageStartTime = 750; // ms
        }
        this.timeouts.push(setTimeout(() => {
            this.showElements();
            this.showLines();
            this.scrollDetect();
        }, this.pageStartTime));
    },
    beforeMount() {
        this.$emit('hideMask');
    },
    beforeUnmount () {
        this.resetPage();
        this.clearTimeouts();
        document.removeEventListener('scroll',this.showWork, { passive: true });
    },
    components: {
        FirstLoading: FirstLoading,
        TopButton: TopButton,
    },
    methods: {
        fetchData() {
            axios.get("https://eldertreen.com/manage/api/works.php", {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                let arrayIndex = 0;
                response.data.forEach((row, key) => {
                    if(arrayIndex >= this.layoutLoop.length) {
                        arrayIndex = 0;
                    }
                    let size = this.layoutLoop[arrayIndex];
                    if(key < this.layoutFirst.length) {
                        size = this.layoutFirst[arrayIndex];
                    }
                    // 마지막이 layoutLoop 3번째일경우 사이즈 조정
                    if(response.data.length == (key+1) && arrayIndex == 2) {
                        size = 'm';
                    }

                    row.type_codes = row.type_codes.split('|');
                    row.size = size;
                    if(row.type_custom != undefined && row.type_custom != '') {
                        row.type_codes.push(row.type_custom);
                    }
                    this.works.push(row);
                    arrayIndex++;
                });
                this.lastIndex = arrayIndex - 1;
            })
            .catch((error) => {
                console.log(error);
            })
        },
        clearTimeouts() {
            this.timeouts.forEach((timeoutId) => {
                clearTimeout(timeoutId);
            });
        },
        resetPage() {
            // fadein animation
            document.querySelectorAll('.fadein-ani').forEach((el) => {
                el.style.transition = 'transform 0s';
                el.style.transform = `translateY(100%)`;
            });
            // fadein animation (opacity)
            document.querySelectorAll('.fadein-opacity-ani').forEach((el) => {
                el.style.transition = 'transform 0s';
                el.style.transform = `translateY(100%)`;
                el.style.opacity = '0';
            });
            // works
            document.querySelectorAll('.work').forEach((el) => {
                el.style.transition = 'opacity 0s';
                el.classList.remove('show');
                el.classList.remove('showing');
            });
            // works
            document.querySelectorAll('.work .work-dim').forEach((el) => {
                el.classList = 'work-dim';
            });
        },
        movePage(path, title) {
            let pageData = {
                path: path,
                title: title,
                param: '',
            };
            this.$emit('movePage', pageData);
        },
        showElements() {
            // fadein animation
            document.querySelectorAll('.fadein-ani').forEach((el) => {
                this.timeouts.push(setTimeout(() => {
                    el.style.transition = 'transform 1s';
                    el.style.transform = `translateY(0)`;
                }, el.dataset.fadeDelay));
            });
            // fadein animation (opacity)
            document.querySelectorAll('.fadein-opacity-ani').forEach((el) => {
                this.timeouts.push(setTimeout(() => {
                    el.style.transition = 'transform 1s cubic-bezier(0.22, 0.61, 0.36, 1), opacity 1s cubic-bezier(0.86, 0.14, 1, 0.91)';
                    el.style.transform = `translateY(0)`;
                    el.style.opacity = '1';
                }, el.dataset.fadeDelay));
            });
        },
        showLines() {
            let lineDelay = 0;
            let lineDelayDef = 0;
            let lineOffDelay = 1000;
            this.timeouts.push(setTimeout(() => {
                this.isShowLine1 = true;
                this.timeouts.push(setTimeout(() => {
                    this.isShowLine1 = false;
                }, lineOffDelay));
            }, lineDelay));
            lineDelay += lineDelayDef;
            this.timeouts.push(setTimeout(() => {
                this.isShowLine2 = true;
                this.timeouts.push(setTimeout(() => {
                    this.isShowLine2 = false;
                }, lineOffDelay));
            }, lineDelay));
            lineDelay += lineDelayDef;
            this.timeouts.push(setTimeout(() => {
                this.isShowLine3 = true;
                this.timeouts.push(setTimeout(() => {
                    this.isShowLine3 = false;
                }, lineOffDelay));
            }, lineDelay));
            lineDelay += lineDelayDef;
            this.timeouts.push(setTimeout(() => {
                this.isShowLineHorizontal = true;
                this.timeouts.push(setTimeout(() => {
                    this.isShowLineHorizontal = false;
                }, lineOffDelay));
            }, lineDelay));
            lineDelay += 300;
            this.timeouts.push(setTimeout(() => {
                this.showWork(); // 최초 1회 실행
            }, lineDelay));
        },
        scrollDetect() {// 스크롤 감지 함수
            document.addEventListener('scroll', this.showWork, { passive: true });
        },
        showWork() {
            let scroll = document.documentElement.scrollTop;

            document.querySelectorAll('.work:not(.showing, .last)').forEach((el) => {
                let elPosY = el.getBoundingClientRect().top + window.pageYOffset + (el.clientHeight/6); // document상 element의 좌표 Y값
                let viewScrollY = scroll + window.innerHeight; // scroll된 값의 밑 기준
                let dimVelocity = 400; // ms

                if((viewScrollY - elPosY) > 0) {
                    this.showDelay += 100;
                    el.classList.add('showing');
                    this.timeouts.push(setTimeout(()=> {
                        let dim = el.getElementsByClassName('work-dim')[0];
                        let slideInAnis = ['slide-in-up', 'slide-in-down', 'slide-in-left', 'slide-in-right'];
                        let slideInRandom = Math.floor(Math.random() * slideInAnis.length);
                        dim.classList.add(slideInAnis[slideInRandom]);
                        this.timeouts.push(setTimeout(()=> {
                            let slideOutAnis = ['slide-out-up', 'slide-out-down', 'slide-out-left', 'slide-out-right'];
                            let slideOutRandom = Math.floor(Math.random() * slideInAnis.length);
                            el.classList.add('show');
                            dim.classList.remove(slideInAnis[slideInRandom]);
                            dim.classList.add(slideOutAnis[slideOutRandom]);
                        }, dimVelocity));
                        this.showDelay = 0;
                    }, this.showDelay));
                }
            });
        },
        hideLodaing() {
            this.isShowLoading = false;
        },
        scrollTop() {
            window.scrollTo({top : 0, behavior: 'smooth'}); 
        },
    },
    emits: ['hideMask', 'movePage', 'openTermsPopup', 'cursorOver', 'cursorOut', 'openMapPopup', 'openAlert'],
}
</script>

<style>
.lines {position:absolute;left:0;top:0;width:100%;height:100%;}
.lines .line {position:absolute;background-color:#555555;z-index:30;width:0.5px;height:100vh}
.lines .line.line-second {right:0;}
.lines .line.line-three {right:33.3333%;}
.line-horizontal {position:absolute;background-color:#555555;z-index:30;width:200vw;height:0.5px;left:-100vw;}
.line-enter-from {transform:translateY(-100%);}
.line-enter-active {transition: transform linear 0.2s;}
.line-enter-to {transform:translateY(0);}
.line-leave-from {opacity:1;transform:translateY(0);}
.line-leave-active {transition: opacity 0.7s;}
.line-leave-to {opacity:0;transform:translateY(0);}
/* .line-leave-from {transform:translateY(0);}
.line-leave-active {transition: transform linear 0.3s;}
.line-leave-to {transform:translateY(100%);} */
.line-horizontal-enter-from {transform:translateX(-100%);}
.line-horizontal-enter-active {transition: transform linear 0.2s;}
.line-horizontal-enter-to {transform:translateX(0);}
.line-horizontal-leave-from {opacity:1;transform:translateX(0);}
.line-horizontal-leave-active {transition: opacity 0.7s;}
.line-horizontal-leave-to {opacity:0;transform:translateX(0);}
/* .line-horizontal-leave-from {transform:translateX(0);}
.line-horizontal-leave-active {transition: transform linear 0.3s;}
.line-horizontal-leave-to {transform:translateX(100%);} */
.scroll-text {position:fixed;display:flex;width:100%;min-width:9999px;margin-top:430px;opacity:0;transition:opacity 1s;}
.scroll-text.show {opacity:1;}
.scroll-text div {color:#F4F5F5;font-family:'Cormorant Garamond';font-size:120px;}
.scroll-text div:first-child {-webkit-animation: scroll-text 66s infinite linear;animation: scroll-text 66s infinite linear;}
.scroll-text div:last-child {-webkit-animation: scroll-text-clone 66s infinite linear;animation: scroll-text-clone 66s infinite linear;}

@media (max-width: 1100px) {
    .lines .line.line-three {display:none;}
}
@media (max-width: 768px) {
    .scroll-text {margin-top:290px}
}
</style>