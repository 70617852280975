import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'
import VueGtag from 'vue-gtag-next'
import { createMetaManager } from 'vue-meta'

const app = createApp(App)
.use(router)
.use(VueGtag, {
    property: {id:'G-PJCM1E23R9',}
})
.use(createMetaManager())

app.mount('#app')