<template>
<div class="container">
    <TopButton @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')"></TopButton>
    <div class="title-corver"></div>
    <div class="video-bg" data-scroll-velocity="1.5">
        <video autoplay loop muted playsinline>
            <source src="@/assets/video/about_bg2.mp4" type="video/mp4">
        </video>
    </div>
    <div class="wrap w1136">
        <div class="title">
            <div class="title-container overflow-hidden" data-scroll-velocity="4"><p class="main-title white fadein-ani" data-fade-delay="0">About</p></div>
            <div class="sub-title-container" data-scroll-velocity="6">
                <div class="scroll-line">
                    <div class="scroll-txt">SCROLL</div>
                    <div class="mouse-svg">
                        <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 7.96516C15.96 3.57516 12.4 0.0351562 8 0.0351562C3.6 0.0351562 0.04 3.57516 0 7.96516V13.9652C0 18.3852 3.58 21.9652 8 21.9652C12.42 21.9652 16 18.3852 16 13.9652V7.96516ZM14 7.96516H9V2.12516C11.81 2.59516 13.96 5.02516 14 7.96516ZM7 2.12516V7.96516H2C2.04 5.02516 4.19 2.59516 7 2.12516ZM14 13.9652C14 17.2752 11.31 19.9652 8 19.9652C4.69 19.9652 2 17.2752 2 13.9652V9.96516H14V13.9652Z" fill="white"/>
                        </svg>
                    </div>
                    <div class="line-box">
                        <div class="line" data-fade-delay="400"></div>
                    </div>
                </div>
                <div class="about-txts">
                    <div class="overflow-hidden"><p class="sub-title fadein-ani" data-fade-delay="500">능력, 지혜, 핵심</p></div>
                    <div class="overflow-hidden">
                        <p class="txt-mini fadein-ani" data-fade-delay="700">
                            <span>ELDER TREE</span>는 딱총나무라고도 하는데 ELDER는 많은 나이를 뜻하는 고대 영어 ELDO에서 유래된 것입니다.<br>
                            이 말속에는 젊은이들을 가르치고 이끌어주며 인식과 행동, 구성원들과의 관계를 바로잡아줄 "능력, 지혜, 핵심"의 의미가 함축되어 있습니다. <br>
                            엘더트리는 생태계에서 핵심종 역할을 하기에 고대 그리스인들은 '식물에 무엇을 해야 하고 어떻게 자라야' 하는지를 가르쳐 주는 것이 이 나무이므로<br>
                            이 나무가 없으면 지역의 식물군락이 혼란에 빠질 것이라고 믿었다고 합니다.<br>
                        </p>
                    </div>
                    <div class="overflow-hidden"><p class="about-txt fadein-ani" data-fade-delay="900">그리고, 우리는 새로운 도약을 위해 ELDER TREE N으로 나아갑니다.</p></div>
                </div>
            </div>
        </div>
        <div class="keyword-container">
            <div class="inner-container">
                <div class="keyword">
                    <p class="keyword-title">Know-how</p>
                    <p class="keyword-content">엘더트리N은 다년간 국내의 유수의 대기업, 금융사 및 공공기관의 프로젝트 경험을 가지고 있는 전문 인력/핵심 인력 중심으로 사업전략팀을 운영하여 대 고객 컨설팅과 영업활동을 진행합니다.</p>
                </div>
                <div class="keyword">
                    <p class="keyword-title">Emotion</p>
                    <p class="keyword-content">새롭고 창조적인 디자인과 사용자 경험치를 극대화하고, 사용하기 용이한 UI/UX 환경을 제공합니다.<br/>웹 표준에 대한 수동 진단과 다각적 진단을 제공하고, 문제점 및 해결 방안을 구체적으로 제시합니다.</p>
                </div>
                <div class="keyword">
                    <p class="keyword-title">R&amp;D</p>
                    <p class="keyword-content">현재 웹은 계속 진화하는 단계이며 이에 따른 지속적인 신기술 연구를 진행합니다.<br/>창조적인 디자인과 혁신적인 기획, 그리고 차별화된 개발 방법론을 연구합니다.</p>
                </div>
            </div>
            <div class="bg-txt-box">
                <div class="bg-txt" data-scroll-velocity="2.5">CREATION THAT</div>
                <div class="bg-txt" data-scroll-velocity="1">GIVE EMOTION</div>
                <div class="bg-txt" data-scroll-velocity="0.5">ON YOUR MIND</div>
            </div>
            <div class="inner-container-section2">
                <a class="work-btn" @click="movePage('/', 'Our Work')" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                    <p>Our Work<br/>다양한 작업물</p>
                    <div class="work-btn-mask"></div>
                </a>
                <div class="keyword-works">
                    <div><span>UI UX Consulting</span></div>
                    <div><span>Service Management</span></div>
                    <div><span>Platform Building</span></div>
                    <div><span>Product Design</span></div>
                    <div><span>Web Publishing</span></div>
                    <div><span>Service Development</span></div>
                    <div><span>Maintenance</span></div>
                    <div><span>Outsourcing</span></div>
                </div>
            </div>
            <div class="inner-container-section3">
                <div class="works-scroll">
                    <div data-scroll-velocity="32"><img alt="work-image1"></div>
                    <div data-scroll-velocity="0"><img alt="work-image2"></div>
                    <div data-scroll-velocity="30"><img alt="work-image3"></div>
                    <div data-scroll-velocity="10"><img alt="work-image4"></div>
                    <div data-scroll-velocity="20"><img alt="work-image5"></div>
                    <div data-scroll-velocity="40"><img alt="work-image6"></div>
                </div>
            </div>
            <div class="inner-container-section4">
                <div class="client-title">
                    <div class="overflow-hidden"><p class="client-title-ko">우리와 함께한 파트너</p></div>
                    <div class="overflow-hidden"><p class="client-title-en">With Partners</p></div>
                </div>
                <div class="clients">
                    <div v-for="(logo, i) in logos" :key="i">
                        <img :src="CDN_GET_PATH+FILE_ABOUT_LOGO_GET_PATH+logo" :alt="logo">
                    </div>
                </div>
            </div>
            <div class="inner-container-section5">
                <div class="work-keywords">
                    <span>MANAGEMENT</span>
                    <span>CONSULTING</span>
                    <span>PLANNING</span>
                    <span>UI/UX</span>
                    <span>DESIGN</span>
                    <span>PUBLISHING</span>
                    <span>DEVELOPER</span>
                    <span>CORPORATION</span>
                    <span>FINANCE</span>
                    <span>PUBLIC</span>
                    <span>MANAGEMENT</span>
                    <span>CONSULTING</span>
                    <span>PLANNING</span>
                    <span>UI/UX</span>
                    <span>DESIGN</span>
                    <span>PUBLISHING</span>
                    <span>DEVELOPER</span>
                    <span>CORPORATION</span>
                    <span>FINANCE</span>
                    <span>PUBLIC</span>
                    <div></div>
                </div>
                <div class="work-keywords">
                    <span>MANAGEMENT</span>
                    <span>CONSULTING</span>
                    <span>PLANNING</span>
                    <span>UI/UX</span>
                    <span>DESIGN</span>
                    <span>PUBLISHING</span>
                    <span>DEVELOPER</span>
                    <span>CORPORATION</span>
                    <span>FINANCE</span>
                    <span>PUBLIC</span>
                    <span>MANAGEMENT</span>
                    <span>CONSULTING</span>
                    <span>PLANNING</span>
                    <span>UI/UX</span>
                    <span>DESIGN</span>
                    <span>PUBLISHING</span>
                    <span>DEVELOPER</span>
                    <span>CORPORATION</span>
                    <span>FINANCE</span>
                    <span>PUBLIC</span>
                    <div></div>
                </div>
            </div>
            <div class="inner-container-section6">
                <div class="overflow-hidden"><p class="location-title">We're here!</p></div>
                <div class="location">
                    <div class="lines">
                        <svg width="100%" height="100%" viewBox="0 0 1128 278" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="line" d="M736 274L0 24" stroke="#E8E8E9" stroke-width="2"/>
                            <path class="line" opacity="0.12" d="M840 186L235 24" stroke="black"/>
                            <path class="line" d="M1120 158L363 17" stroke="#E8E8E9"/>
                            <path class="line" d="M672 277L1191 -4" stroke="#E8E8E9" stroke-width="2"/>
                            <path class="line" d="M452 222L1110 -4" stroke="#E8E8E9"/>
                            <path class="line" d="M337 205L895 2" stroke="#E8E8E9"/>
                            <path class="line" d="M122 152L708 -3" stroke="#E8E8E9"/>
                        </svg>
                    </div>
                    <div class="place">
                        <p>망원역</p>
                        <p>한국장애인<br/>복지관협회</p>
                        <p>최규하대통령가옥</p>
                        <p>합정역</p>
                        <p>메리골드호텔</p>
                        <p>홍대입구역</p>
                    </div>
                    <div class="marker" @click="openMapPopup" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                        <svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 0.666626C4.84 0.666626 0.666668 4.83996 0.666668 9.99996C0.666668 17 10 27.3333 10 27.3333C10 27.3333 19.3333 17 19.3333 9.99996C19.3333 4.83996 15.16 0.666626 10 0.666626ZM10 13.3333C8.16 13.3333 6.66667 11.84 6.66667 9.99996C6.66667 8.15996 8.16 6.66663 10 6.66663C11.84 6.66663 13.3333 8.15996 13.3333 9.99996C13.3333 11.84 11.84 13.3333 10 13.3333Z" fill="#1A1A1B"/>
                        </svg>
                    </div>
                </div>
                <div class="location-info">
                    <table>
                        <colgroup>
                            <col width="72px">
                            <col>
                        </colgroup>
                        <tr>
                            <td>주소</td>
                            <td>서울특별시 마포구 월드컵로10길 48, 302호</td>
                        </tr>
                        <tr>
                            <td>전화</td>
                            <td>02  322  7776</td>
                        </tr>
                        <tr>
                            <td>팩스</td>
                            <td>02  332  7220</td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>eldern@eldertreen.com</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="inner-container-section7">
                <p class="last-comment">ARE YOU</p>
                <div class="overflow-hidden"><p class="last-small-comment">저희와 함께하실 준비가 되었나요?</p></div>
                <p class="last-comment">READY TO<span>&nbsp;</span>START US?</p>
                <div class="last-btns">
                    <div class="move-container">
                        <a class="button large icon button-white" @click="movePage('/contact-us', 'Contact us')" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <div class="button-contents">
                                <div class="button-icon">
                                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.0827 11.2773L7.60093 10.4999H6.66667H2.00001C1.54281 10.4999 1.16667 10.1238 1.16667 9.66659V1.66659C1.16667 1.20939 1.54281 0.833252 2.00001 0.833252H14C14.4572 0.833252 14.8333 1.20939 14.8333 1.66659V9.66659C14.8333 10.1238 14.4572 10.4999 14 10.4999H9.33334H8.39908L8.91731 11.2773L10.1667 13.1513V13.1666H5.83334V13.1513L7.0827 11.2773ZM14 8.83325H14.5V8.33325V1.66659V1.16659H14H2.00001H1.50001V1.66659V8.33325V8.83325H2.00001H14Z" fill="#1A1A1B" stroke="#1A1A1B"/>
                                    </svg>
                                </div>            
                                <span>프로젝트 문의하기</span>
                            </div>
                        </a>
                    </div>
                    <div class="move-container">
                        <a class="button large icon button-non-bg" @click="movePage('/contact-us?type=join', 'Contact us')" @mouseover="this.$emit('cursorOver')" @mouseout="this.$emit('cursorOut')">
                            <div class="button-contents">
                                <div class="button-icon">
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.16667 3.33341C8.16667 4.53061 7.19719 5.50008 6 5.50008C4.80281 5.50008 3.83333 4.53061 3.83333 3.33341C3.83333 2.13622 4.80281 1.16675 6 1.16675C7.19719 1.16675 8.16667 2.13622 8.16667 3.33341ZM1.16667 10.0001C1.16667 9.74039 1.29279 9.47592 1.5888 9.20271C1.88821 8.92638 2.32687 8.67617 2.85177 8.46589C3.90238 8.04502 5.1725 7.83341 6 7.83341C6.8275 7.83341 8.09762 8.04502 9.14823 8.46589C9.67313 8.67617 10.1118 8.92638 10.4112 9.20271C10.7072 9.47592 10.8333 9.74039 10.8333 10.0001V10.8334H1.16667V10.0001Z" fill="white" stroke="white"/>
                                    </svg>
                                </div>           
                                <span>입사지원</span>                       
                            </div>            
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import TopButton from './common/TopButton.vue';
import axios from 'axios'
import { useMeta } from 'vue-meta'

export default {
    name: 'TheAbout',
    props: {
        isLoading: Boolean,
    },
    components: {
        TopButton: TopButton,
    },
    data() {
        return {
            keywordShowDelay: 0, // ms
            delayValue: 100,
            isShowingMap: false,
            pageStartTime: 0,
            timeouts: [], // setTimeout을 사용할 땐 이 변수에 ID를 넣어야한다.
            isShowMarker: false,
            logos: {},
            CDN_GET_PATH: 'https://eldertreen.cdn1.cafe24.com',
            FILE_ABOUT_THUMBNAIL_GET_PATH: '/about/thumbnail/',
            FILE_ABOUT_LOGO_GET_PATH: '/about/logo/',
        }
    },
    setup () {
        useMeta({ title: 'About' })
    },
    created() {
        this.fetchData();
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        document.body.classList.add('white');
        document.querySelector('.title-corver').classList.remove('show');
        if(!this.isLoading) {
            this.pageStartTime = 750; // ms
        }
        let toLoad = setTimeout(() => {
            this.showElements();
            this.parallaxDetect();
            this.setParallaxs();
        }, this.pageStartTime);
        this.timeouts.push(toLoad);
    },
    beforeMount() {
        this.$emit('hideMask');
    },
    beforeUnmount() {
        this.hideElements();
        this.clearTimeouts();
        document.removeEventListener('scroll', this.setParallaxs, { passive: true });
    },
    methods: {
        fetchData() {
            axios.get("https://eldertreen.com/manage/api/about.php", {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if(response.status == 200) {
                    this.logos = response.data.logos;
                    // work images
                    let works = document.querySelectorAll('.works-scroll>div');
                    works.forEach((work, key) => {
                        work.querySelector('img').src = this.CDN_GET_PATH+this.FILE_ABOUT_THUMBNAIL_GET_PATH+response.data.thumbnails[key];
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
        },
        movePage(path, title, param) {
            let sendParam = '';
            if(param != undefined && param != '') {
                sendParam = param;
            }
            let pageData = {
                path: path,
                title: title,
                param: sendParam,
            };
            this.$emit('movePage', pageData);
        },
        clearTimeouts() {
            this.timeouts.forEach((timeoutId) => {
                clearTimeout(timeoutId);
            });
        },
        hideElements() {
            // fadein-anis
            document.querySelectorAll('.fadein-ani').forEach((el) => {
                el.style.transition = 'transform 0s';
                el.style.transform = `translateY(100%)`;
            });
            //keywords
            let workKeywords = document.querySelectorAll('.inner-container-section2 .keyword-works span');
            workKeywords.forEach((keyword) => {
                keyword.classList.remove('show');
            });
            document.querySelectorAll('.work-btn').forEach((el) => {
                el.style.transition = 'none';
                el.style.transform = `translateY(100%)`;
                el.style.opacity = '0';
            });
            // clients
            // 클라이언트 타이틀 (한국어)
            let titleKo = document.querySelector('.client-title-ko');
            titleKo.classList.remove('show');
            // 클라이언트 타이틀 (영어)
            let titleEn = document.querySelector('.client-title-en');
            titleEn.classList.remove('show');
            // 클라이언트 로고
            let clients = document.querySelectorAll('.clients div');
            clients.forEach((clientEl) => {
                clientEl.classList.remove('show');
            });
            // 지도
            // 지도 타이틀
            let locationTitle = document.querySelector('.location-title');
            locationTitle.classList.remove('show');
            document.querySelectorAll('.lines .line').forEach((line) => {
                line.classList.remove('show');
            });
            document.querySelectorAll('.place p').forEach((place) => {
                place.classList.remove('show');
            });
            document.querySelector('.location .marker').classList.remove('fix-mode');
            document.querySelector('.location .marker').classList.remove('show');
            document.querySelector('.location-info').classList.remove('show');
            // -- last -- //
            let lastComment = document.querySelectorAll('.last-comment')[0];
            let lastComment2 = document.querySelectorAll('.last-comment')[1];
            lastComment.classList.remove('show');
            lastComment2.classList.remove('show');
            document.querySelector('.last-small-comment').classList.remove('show');
            document.querySelector('.last-btns .move-container:nth-child(1)').classList.remove('show');
            document.querySelector('.last-btns .move-container:nth-child(2)').classList.remove('show');
        },
        showElements() {
            // fadein animation
            document.querySelectorAll('.fadein-ani').forEach((el) => {
                let startTime = 0; // 기본 0ms
                if(el.dataset.fadeDelay) {
                    startTime = el.dataset.fadeDelay; // 지정한 delay가 있으면 변수 초기화
                }
                this.timeouts.push(setTimeout(() => {
                    el.style.transition = 'transform 1s';
                    el.style.transform = `translateY(0)`;
                }, startTime));
            });
        },
        parallaxDetect() {
            document.addEventListener('scroll', this.setParallaxs, { passive: true });
        },
        setParallaxs() {
            let scroll = document.documentElement.scrollTop;
            this.changeViewType(scroll);
            this.moveBgText(scroll);
            this.worksKeyword(scroll);
            this.worksParallax(scroll);
            this.showClients(scroll);
            this.showLocation(scroll);
            this.showWithUs(scroll);
        },
        // dark - white 전환
        changeViewType(scroll) {
            if(scroll > 700) {
                // transition:background-color 1s;
                document.querySelector('.wrap .keyword-container').classList.add('bg-color-1s');
                document.querySelectorAll('.wrap .keyword-container .keyword-title').forEach((el) => {
                    el.classList.add('color-1s');
                });
                document.querySelectorAll('.wrap .keyword-container .keyword-content').forEach((el) => {
                    el.classList.add('color-1s');
                });
                document.body.classList.remove('white');

                // 뒷 배경 마스크
                document.querySelector('.title-corver').classList.add('show');
                document.querySelector('.wrap .title').classList.add('hide');
            }
            if(scroll > 600) {
                document.querySelectorAll('.wrap .keyword-container .bg-txt-box .bg-txt').forEach((el) => {
                    el.classList.add('show');
                });
            }
            else if(!document.body.classList.contains('white')) {
                document.body.classList.add('white');
                // 뒷 배경 마스크
                document.querySelector('.title-corver').classList.remove('show');
                document.querySelector('.wrap .title').classList.remove('hide');
            }
        },
        // bg-txt animation
        moveBgText(scroll) {
            let bgTexts = document.querySelectorAll('.bg-txt');
            let movePower = 0.02;

            bgTexts.forEach((el, key) => {
                let indexValue = ((key+1) * 0.3);
                let velocity = el.dataset.scrollVelocity ? el.dataset.scrollVelocity : 1;
                // indexValue = 1;
                let movePx = Math.floor(scroll * movePower * indexValue * velocity);

                if(key%2 == 0) {
                    this.moveRight(el, movePx);
                } else {
                    this.moveLeft(el, movePx);
                }
            });
        },
        // show keyword works
        worksKeyword(scroll) {
            let workKeywords = document.querySelectorAll('.inner-container-section2 .keyword-works span');
            let detectScrolls = this.getDetectScrolls(workKeywords.length);
            workKeywords.forEach((keyword, key) => {
                if(scroll > detectScrolls[key]) {
                    this.timeouts.push(setTimeout(()=>{workKeywords[key].classList.add('show');},this.keywordShowDelay));
                    this.keywordShowDelay += this.delayValue;
                    if(workKeywords.length-1 == key) {
                        document.querySelectorAll('.work-btn').forEach((el) => {
                            this.timeouts.push(setTimeout(() => {
                                el.style.transition = 'transform 1s cubic-bezier(0.22, 0.61, 0.36, 1), opacity 1s cubic-bezier(0.86, 0.14, 1, 0.91)';
                                el.style.transform = `translateY(0)`;
                                el.style.opacity = '1';
                            },this.keywordShowDelay));
                        });
                    }
                }
            });
            this.keywordShowDelay = 0;
        },
        getDetectScrolls(arrayLength) {
            let returnArray = [];
            let startValue = 1100;
            let plusValue = 50;
            if(this.isMobileByWidth()) {
                startValue = 700;
            }
            for(let i = 0; i < arrayLength; i++) {
                returnArray.push(startValue + (plusValue*i));
            }
            return returnArray;
        },
        // works parallax
        worksParallax(scroll) {
            let worksContainer = document.querySelector('.works-scroll');
            let works = document.querySelectorAll('.works-scroll>div');
            let elPosY = Math.floor(worksContainer.getBoundingClientRect().top + window.pageYOffset + (worksContainer.clientHeight/2)); // document상 element의 좌표 Y값
            let viewScrollY = scroll + (window.innerHeight/2); // scroll된 값의 밑 기준
            // scroll 값이 element의 Y 좌표값을 넘었을 경우
            works.forEach((el) => {
                let workVelocity = el.dataset.scrollVelocity ? el.dataset.scrollVelocity : 10;
                let movePower = 0.0015;
                let movePx = Math.floor(workVelocity * (viewScrollY - elPosY) * movePower);
                this.vertical(el, movePx);
            });
            // if((viewScrollY - elPosY) >= 0) {
                
            // } else {
            //     // works.forEach((el, key) => {
            //     //     verticalZero(el);
            //     // });
            // }
        },
        // client show animation
        showClients(scroll) {
            let viewScrollY = scroll + window.innerHeight;
            // 클라이언트 타이틀 (한국어)
            let titleKo = document.querySelector('.client-title-ko');
            let titleKoPosY = Math.floor(titleKo.getBoundingClientRect().top + window.pageYOffset + titleKo.clientHeight); // document상 element의 좌표 Y값
            if((viewScrollY-titleKoPosY) > 0) {
                titleKo.classList.add('show');
            }
            // 클라이언트 타이틀 (영어)
            let titleEn = document.querySelector('.client-title-en');
            let titleEnPosY = Math.floor(titleEn.getBoundingClientRect().top + window.pageYOffset + titleEn.clientHeight); // document상 element의 좌표 Y값
            if((viewScrollY-titleEnPosY) > 0) {
                titleEn.classList.add('show');
            }
            // 클라이언트 로고
            let clientsBox = document.querySelector('.clients'); 
            let clients = document.querySelectorAll('.clients div');
            let clientsBoxPosY = Math.floor(clientsBox.getBoundingClientRect().top + window.pageYOffset); // document상 element의 좌표 Y값
            if((viewScrollY-clientsBoxPosY) >= 0) {
                let delay = 100;
                let showDelay = delay;
                clients.forEach((clientEl) => {
                    this.timeouts.push(setTimeout(() => {clientEl.classList.add('show');}, showDelay));
                    showDelay += delay;
                });
            }
        },
        // location show animation
        showLocation(scroll) {
            if(this.isShowingMap) {
                return;
            }
            let viewScrollY = scroll + window.innerHeight;
            // 지도 타이틀
            let locationTitle = document.querySelector('.location-title');
            let locationTitlePosY = Math.floor(locationTitle.getBoundingClientRect().top + window.pageYOffset + locationTitle.clientHeight); // document상 element의 좌표 Y값

            if((viewScrollY-locationTitlePosY) >= 0) {
                this.isShowingMap = true;
                // SHOW TITLE
                locationTitle.classList.add('show');
                // SHOW LOCATION
                let delay = 50;
                let showDelay = 50;
                document.querySelectorAll('.lines .line').forEach((line) => {
                    this.timeouts.push(setTimeout(() => {line.classList.add('show');}, showDelay));
                    showDelay += delay;
                });
                // SHOW LOCATION INFO
                showDelay += 50; // marker 모션이 끝난 후 INFO 모션 실행
                this.timeouts.push(setTimeout(() => {
                    document.querySelector('.location-info').classList.add('show');
                    this.isShowingMap = false;
                }, showDelay));
                // SHOW PLACE
                showDelay += 400; // line 모션이 끝난 후 place 모션 실행
                delay = 100;
                document.querySelectorAll('.place p').forEach((place) => {
                    this.timeouts.push(setTimeout(() => {place.classList.add('show');}, showDelay));
                    showDelay += delay;
                });
                // SHOW MARKER
                if(!this.isShowMarker) {
                    this.isShowMarker = true;
                    showDelay += 100; // place 모션이 끝난 후 marker 모션 실행
                    this.timeouts.push(setTimeout(() => {
                        document.querySelector('.location .marker').classList.add('show');
                        this.timeouts.push(setTimeout(()=> {
                            document.querySelector('.location .marker').classList.remove('show');
                            document.querySelector('.location .marker').classList.add('fix-mode');
                        }, 900)); // 1초뒤 hover를 가능하도록 수정
                    }, showDelay));
                }
            }
        },
        // with us show animation
        showWithUs(scroll) {
            let viewScrollY = scroll + window.innerHeight;
            let lastComment = document.querySelectorAll('.last-comment')[0];
            let lastComment2 = document.querySelectorAll('.last-comment')[1];
            let lastCommentPosY = Math.floor(lastComment.getBoundingClientRect().top + window.pageYOffset + lastComment.clientHeight); // document상 element의 좌표 Y값
            
            if((viewScrollY-lastCommentPosY) >= 0) {
                lastComment.classList.add('show');
                
                this.timeouts.push(setTimeout(() => {lastComment2.classList.add('show');}, 600));
                this.timeouts.push(setTimeout(() => {document.querySelector('.last-small-comment').classList.add('show')}, 1200));
                this.timeouts.push(setTimeout(() => {document.querySelector('.last-btns .move-container:nth-child(1)').classList.add('show');}, 2200));
                this.timeouts.push(setTimeout(() => {document.querySelector('.last-btns .move-container:nth-child(2)').classList.add('show');}, 2400));
            } 
        },
        // -- trnasform function -- //
        vertical(el, movePx) {
            el.style.transform = `translateY(${movePx}px)`;
        },
        moveLeft(el, movePx) {
            el.style.transform = `translateX(-${movePx}%)`;
        },
        moveRight(el, movePx) {
            el.style.transform = `translateX(${movePx}%)`;
        },
        openMapPopup() {
            this.$emit("openMapPopup");
        },
        isMobileByWidth() {return 748 > window.innerWidth;},
    },
    emits: ['hideMask', 'movePage', 'openMapPopup', 'cursorOver', 'cursorOut'],
}
</script>

<style>
</style>