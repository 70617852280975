<template>
    <div class="load" v-if="isShowFirstMask == true">
        <Transition name="load-txt">
            <div class="txt" v-if="isShowFirstTxt">{{year}}</div>
        </Transition>
        <Transition name="load-bar">
            <div class="bar" v-if="isShowFirstMaskBar"></div>
        </Transition>
        <Transition name="load-bg-top">
            <div class="bg-top" v-if="isShowFirstMaskBg"></div>
        </Transition>
        <Transition name="load-bg-bottom">
            <div class="bg-bottom" v-if="isShowFirstMaskBg"></div>
        </Transition>
    </div>
</template>

<script>
export default {
    name: 'FirstLoading',
    data() {
        return {
            isShowFirstMask: true,
            isShowFirstMaskBg: true,
            isShowFirstTxt: false,
            isShowFirstMaskBar: false,
            year: 2012,
            barElement: undefined,
            toDayYear: undefined,
            startYear: 2012, // 시작 년도
            yearDiff: undefined, // 시작 년도와 올해 년도 차이
            yearInterval: undefined,
            actionTime: 1500, // ms
            updateTime: undefined, // 100ms -> 0.1초
        }
    },
    mounted() {
        this.setup();
        this.isShowFirstTxt = true;
        setTimeout(() => {
            this.isShowFirstMaskBar = true;
            this.yearInterval = setInterval(this.updateYear, this.updateTime);
        }, 1000);
    },
    methods: {
        setup() {
            let toDate = new Date();
            this.year = 2012;
            this.barElement = document.querySelector('.load .bar');
            this.toDayYear = toDate.getFullYear();
            this.startYear = 2012; // 시작 년도
            this.yearDiff = this.toDayYear - this.startYear; // 시작 년도와 올해 년도 차이
            this.yearInterval = undefined;
            this.actionTime = 1500; // ms
            this.updateTime = this.actionTime / this.yearDiff; // 100ms -> 0.1초
        },
        updateYear() {
            // let vw = window.innerWidth; // 현재 브라우저 창의 가로 길이
            // let barw = this.barElement.offsetWidth; // 현재 로딩바의 길이
            // let barPercent = Math.floor((barw/vw)*100); // 로딩 값 (%)
            let yearDiff = this.toDayYear - this.startYear; // 시작 년도와 올해 년도 차이
            
            // 올해년도와 같거나 적어지면 interval 종료
            if(yearDiff < 1) {
                clearInterval(this.yearInterval);
                setTimeout(() => {
                this.isShowFirstMaskBar = false;
                this.isShowFirstTxt = false;
                }, 600);
                setTimeout(() => {
                    this.isShowFirstMaskBg = false;
                }, 1100);
                return;
            }
            this.year = ++this.startYear;
        },
        // 스크롤 막기
        scrollLock() {
            document.body.style.overflow = 'hidden';
        },
        // 스크롤 풀기
        scrollUnlock() {
            document.body.style.overflow = 'auto';
        },
    },
}
</script>

<style>
.load {position:fixed;z-index:40000;top:0;left:0;}
.load .bg-top {height:50vh;width:100vw;background-color:#F4F5F5;}
.load .bg-bottom {height:50vh;width:100vw;background-color:#F4F5F5;}
.load .txt {
    width: 100vw;
    text-align: center;
    position: fixed;
    font-size: 40px;
    line-height: 160%;
    letter-spacing: -0.03em;
    transform: translateY(-100%);
    top: 48%;
}
.load .bar {
    height: 1px;
    width: 100%;
    background-color: #000;
    position: fixed;
    top: 50%;
}
.load-bar-enter-from {transform:translateX(-100%);}
.load-bar-enter-active {transition: transform 1500ms ease-out}
.load-bar-enter-to {transform:translateX(0);}
.load-bar-leave-from {transform:translateX(0);}
.load-bar-leave-active {transition: transform 500ms ease-out}
.load-bar-leave-to {transform:translateX(100%);}
.load.hiding {
    opacity: 0;
}
.load.hiding .txt {
    transform: translateY(-120%);
}
.load-txt-enter-from {opacity:0;}
.load-txt-enter-active {transition: opacity ease-out 1000ms;}
.load-txt-enter-to {opacity:1;}
.load-txt-leave-from {opacity:1;}
.load-txt-leave-active {transition: opacity ease-out 500ms;}
.load-txt-leave-to {opacity:0;}
.load-bg-top-leave-from {transform: translateX(0%);}
.load-bg-top-leave-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .75s;}
.load-bg-top-leave-to {transform: translateX(-100%);}
.load-bg-bottom-leave-from {transform: translateX(0%);}
.load-bg-bottom-leave-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .75s;}
.load-bg-bottom-leave-to {transform: translateX(100%);}
</style>