<template>
<a class="top-btn" id="topBtn" @click="scrollTop">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 3.83V15.5H7.5V3.83V2.62442L6.64676 3.47613L1.41032 8.70321L0.707107 8L8 0.707107L15.2929 8L14.5803 8.71258L9.35387 3.47676L8.5 2.62136V3.83Z" fill="black" stroke="#1A1A1B"/>
    </svg>                
</a>
</template>

<script>
export default {
    name: 'TopButton',
    data() {
        return {
            beforeScroll: 0,
        }
    },
    mounted() {
        this.setScrollEvent();
    },
    methods: {
        scrollTop() {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: document.body.offsetTop
            });
        },
        setScrollEvent() {
            document.addEventListener('scroll', () => {
                let scroll = document.documentElement.scrollTop;
                let winHeight = window.innerHeight;
                let docHeight = document.body.scrollHeight;
                let footerHeight = document.querySelector('.footer').offsetHeight;
                let floatPos = parseInt(docHeight) - parseInt(winHeight) - footerHeight;
                let mobileCondition = true;
                let btnTop = document.getElementById('topBtn');

                if(btnTop == undefined) {
                    return;
                }

                if(scroll > floatPos){
                    btnTop.classList.add('fix');
                } else {
                    btnTop.classList.remove('fix');
                }
                if(this.isMobile()) {
                    mobileCondition = scroll > this.beforeScroll;
                }

                if (scroll > 200 && mobileCondition) {
                    btnTop.classList.add('show');
                } else {
                    btnTop.classList.remove('show');
                }
                this.beforeScroll = scroll;
            }, { passive: true });
        },
        isMobile() {return /Android|webOS|iPhone|iPad|iPod|X11|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);},
    },
}
</script>

<style> 
@media (hover: hover) {
    .top-btn:hover {
        border: 1px solid #ffffff;
        background-color: #1a1a1b;
    }
    .top-btn:hover svg path {
        fill: #ffffff;
        stroke: #ffffff;
    }
}
</style>