<template>
    <Transition name="page-mask">
        <div class="page-mask" v-if="isShowMask == true">
        </div>
    </Transition>
    <div class="page-mask-text overflow-hidden">
        <Transition name="page-mask-title">
            <div class="page-title" v-if="isShowMask == true">{{pageTitle}}</div>
        </Transition>
        <Transition name="page-mask-title-underline">
            <div class="page-title-underline" v-if="isShowMask == true"></div>
        </Transition>
    </div>
    <Popup
        ref="popup"
    ></Popup>
  <Header 
    ref="header"
    @movePage="showMask($event)"
    @cursorOver="cursorOver()"
    @cursorOut="cursorOut()"
    :key="headerComponentKey"
    ></Header>
    <!-- <scrollbar> -->
      <!-- <div class="scroll-content"> -->
      <router-view
          @hideMask="hideMask"
          @movePage="showMask($event)"
          @openTermsPopup="openTermsPopup"
          @openMapPopup="openMapPopup"
          @openAlert="openAlert($event)"
          @cursorOver="cursorOver()"
          @cursorOut="cursorOut()"
          :isLoading="isLoading"
          :key="contentComponentKey"
          ></router-view>
      <Footer/>
      <!-- </div> -->
    <!-- </scrollbar> -->
</template>

<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
import Popup from '../common/Popup.vue';
import axios from 'axios'
// import Scrollbar from "vue3-smooth-scrollbar";

export default {
    name: 'MainLayout',
    data() {
        return {
            headerComponentKey: 0,
            contentComponentKey: 0,
            pageTitle: '',
            isShowMask: false,
            isLoading: true,
            mouseCursor: '',
            userAgent: navigator.userAgent,
            smoothScroll: '',
        }
    },
    created() {
        this.pageLog();
    },
    mounted() {
        this.cursor();
        this.cursorUseTest();
        this.browserResizeDetect();
        this.btnTop();
        // SCROLL
        // Scrollbar.init(document.querySelector('#my-scrollbar'), {});
    },
    components: {
        Header: Header,
        Footer: Footer,
        Popup: Popup,
        // Scrollbar: Scrollbar,
    },
    methods: {
        pageLog() {
            axios({
              method: 'post',
              url: 'https://eldertreen.com/manage/api/checkout.php',
              params: {
                path: this.$route.path,
              }
            })
            .then(function () {
              // console.log(response);
            })
            .catch(function () {
              // console.log(error);
            });
        },
        /* page 전환 효과 */
        showMask(pageData) {
            this.isLoading = false;
            document.body.scrollTop = 0;
            this.prohibitEvent();
            this.isShowMask = true;
            this.pageTitle = pageData.title;
            setTimeout(() => {
                this.headerComponentKey += 1; // header componet re-render
                // 만약 같은 페이지일 경우 재랜더링
                if(this.$route.path == pageData.path) {
                    this.contentComponentKey += 1; // content componet re-render
                } else {
                    // 다른 페이지일 경우 push
                    this.$router.push(pageData.path);
                }
            }, 1000);
        },
        hideMask() {
            this.isShowMask = false;
            this.offProhibitEvent();
        },
        prohibitEvent() {
            document.body.classList.add('prohibit-event');
            this.scrollLock();
        },
        offProhibitEvent() {
            this.scrollUnlock();
            document.body.classList.remove('prohibit-event');
        },
        openTermsPopup() {
            this.$refs.popup.openTermsPopup();
        },
        openMapPopup() {
            this.$refs.popup.openMapPopup();
        },
        openAlert(option) {
            this.$refs.popup.openAlert(option);
        },
        /* device check */
        isMobile() {
            const userAgent = navigator.userAgent.toLowerCase();
            const maxTouchPoint = navigator.maxTouchPoints;
            const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
            const isIosSafari = (navigator.userAgent.match(/Mac OS X/i));
            const isIPad = maxTouchPoint > 0 && isIosSafari;
            return navigator.userAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || navigator.userAgent.match(/LG|SAMSUNG|Samsung/) != null || isTablet || isIPad;
        },
        isMobileByWidth() {return 748 > window.innerWidth;},
        /* cursor setting */
        cursor() {
            /* --- cursor --- */
            let cursorElement = document.createElement('div');
            let cursorBorder = document.createElement('div');
            cursorElement.className = 'cursor';
            cursorBorder.className = 'cursor-border';
            cursorElement.appendChild(cursorBorder);
            document.body.appendChild(cursorElement);
            this.mouseCursor = cursorElement;
            
            let mouseX = -9999;
            let mouseY = -9999;
            
            let cursorX = -9999;
            let cursorY = -9999;
            
            let speed = 1;
            
            let scale = '1,1';
            
            const cursorAnimate = () => {
                let distX = mouseX - cursorX;
                let distY = mouseY - cursorY;
                let translatedValue = `translate3d(${cursorX}px, ${cursorY}px, 0) scale(${scale})`;
                cursorX = cursorX + (distX * speed);
                cursorY = cursorY + (distY * speed);   
                
                cursorElement.style.transform = translatedValue;
                requestAnimationFrame(cursorAnimate);
            }
            cursorAnimate();
            document.addEventListener('mousemove', (e) => {
                mouseX = e.clientX - (cursorElement.offsetWidth/2);
                mouseY = e.clientY - (cursorElement.offsetWidth/2);
            });
        },    
        cursorOver() {
            this.mouseCursor.classList.add("type-pointer");
        },
        cursorOut() {
            this.mouseCursor.classList.remove("type-pointer");
        },
        /* mobile cursor */
        cursorUseTest() {
            let curosrDisplay = this.isMobile() ? 'none' : 'block';
            document.querySelectorAll('.cursor').forEach((el) => {
                el.style.display = curosrDisplay;
            });
        },
        /* html size 변경 감지 */
        browserResizeDetect() {
            window.onresize = () => {
                this.cursorUseTest(); // check mobile cursor
            };
        },
        /* --- cursor --- */
        /* --- util ---*/
        // Math - random
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //최댓값은 제외, 최솟값은 포함
        },
        // element의 opacity 조절
        elementOpacity(el, opacity) {
            if(el instanceof Element) {
                el.style.opacity = opacity;
            }
        },
        // element가 undefiend 혹은 null 일 경우 true
        isNullElement(el) {
            return el == null || el == undefined;
        },
        // element가 Function instance 일 경우 true
        isFunction(myFunction) {
            return !this.isNullElement(myFunction) && myFunction instanceof Function;
        },
        // class로 자식노드 찾기
        findChildByClass(parentNode, childClass) {
            let childNodes = parentNode.childNodes;
            for(let i = 0; i < childNodes.length; i++) {
                if(childNodes[i] instanceof Element) {
                    if(childNodes[i].classList.contains(childClass)) {
                        return childNodes[i];
                    }
                }
            }
        },
        /* document 조작 */
        // 스크롤 막기
        scrollLock() {
            document.body.style.overflow = 'hidden';
        },
        // 스크롤 풀기
        scrollUnlock() {
            document.body.style.overflow = 'auto';
        },
        // scroll 감지
        btnTop() {
            let btnTop = document.querySelector('.top-btn');
            let beforeScroll = 0;
            if(this.isNullElement(btnTop)) {
                return;
            }

            document.addEventListener('scroll', () => {
                let scroll = document.documentElement.scrollTop;
                let winHeight = window.innerHeight;
                let docHeight = document.body.scrollHeight;
                let footerHeight = document.querySelector('.footer').offsetHeight;
                let floatPos = parseInt(docHeight) - parseInt(winHeight) - footerHeight;
                let mobileCondition = true;

                if(scroll > floatPos){
                    btnTop.classList.add('fix');
                } else {
                    btnTop.classList.remove('fix');
                }

                if(this.isMobile()) {
                    mobileCondition = scroll > beforeScroll;
                }

                if (scroll > 200 && mobileCondition) {
                    btnTop.classList.add('show');
                } else {
                    btnTop.classList.remove('show');
                }
                beforeScroll = scroll;
            }, { passive: true });
            btnTop.addEventListener('click', (e) => {
                e.preventDefault();
                window.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: document.body.offsetTop
                });
            });
        },
    },
}
</script>
<style>
/* @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css); */
@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    src: local('Spoqa Han Sans Neo Bold'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
  font-display: swap;
}


@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 500;
    src: local('Spoqa Han Sans Neo Medium'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    src: local('Spoqa Han Sans Neo Regular'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 300;
    src: local('Spoqa Han Sans Neo Light'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.ttf') format('truetype');
  font-display: swap;
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 100;
    src: local('Spoqa Han Sans Neo Thin'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.ttf') format('truetype');
  font-display: swap;
}
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap");
/* COLOR */
* {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
  color: #1A1A1B;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
  cursor: none;
  word-break: keep-all;
}

::selection {
    background-color: #1a1a1b;
    color: #ffffff;
}

html {
  height: 100%;
  font-size: 10px;
}

body {
  background-color: #FFFFFF;
  /*-ms-overflow-style: none;  IE and Edge */
  /*scrollbar-width: none; /* Firefox */
  /*overflow: hidden; */
}
body.prohibit-event::before {
    content:'';
    display:block;
    position:fixed;
    left:0;
    top:0;
    width:100vw;
    height:100vh;
    z-index:30000;
}

body::-webkit-scrollbar {
  display: none;
}
/*  SCROLL
#app {
  height: 100vh;
  overflow: auto;
} */

div, p, span, tr, td, a, input {
  line-height: 160%;
}

/* width */
.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.w-10 {
  width: 25%;
}

.mx-200 {
  max-width: 200px;
}

/* margin */
.mt-32 {
  margin-top: 32px;
}

.mt-60 {
  margin-top: 60px;
}

/* padding */
.pb-0 {
  padding-bottom: 0;
}
/* transition */
.none-transition {
  -webkit-transition: none;
  transition: none;
}

/* flex layout class*/
.flex-direction-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

/* center element */
.center {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* animation class */
.color-1s {
  -webkit-transition: color 1s;
  transition: color 1s;
}

.opacity-1s {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.bg-color-1s {
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
}

.color-and-opacity-1s {
  -webkit-transition: color 1s opacity 1s;
  transition: color 1s opacity 1s;
}

.delay-ani-1s {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

/* color */
.bg-darkblack {
  background-color: #1A1A1B;
}

#my-scrollbar {
  
}

/* button */
a {
  text-decoration: none;
}

a.button {
  display: inline-block;
  color: #FFFFFF;
  background-color: #28292A;
  border: solid 1px #28292A;
  font-size: 16px;
  text-align: center;
  -webkit-transition: color 0.5s, background-color 0.5s, border 0.5s;
  transition: color 0.5s, background-color 0.5s, border 0.5s;
}

a.button.button-blue {
  color: #FFFFFF;
  background-color: #0062D4;
  border: 1px solid #0062D4;
}

a.button.button-blue span {
  color: #FFFFFF;
}

a.button.button-white {
  color: #28292A;
  background-color: #FFFFFF;
}

a.button.button-white span {
  color: #28292A;
}

a.button.button-gray {
  color: #28292A;
  background-color: #E8E8E9;
  border: 1px solid #E8E8E9;
}

a.button.button-gray span {
  color: #28292A;
}

a.button.button-non-bg {
  color: #28292A;
  background-color: transparent;
  border: 1px solid hsla(0,0%,100%,1);;
}

a.button.button-non-bg span {
  color: #FFFFFF;
}

a.button.disabled {
  background-color: #C5C7C9;
  border: solid 1px #C5C7C9;
}

a.button.large {
  padding: 16px 32px;
  border-radius: 8px;
  font-size: 1.6rem;
}

a.button.medium {
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 1.4rem;
}

a.button.small {
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 1.2rem;
}

a.button.full-radius {
  border-radius: 100px;
}

a.button.only-icon {
  padding: 9.5px;
}
a.button.icon .button-icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
}

a.button.icon .button-icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

a.button.icon .button-icon svg * {
  -webkit-transition: fill 0.5s, stroke 0.5s;
  transition: fill 0.5s, stroke 0.5s;
}

a.button.icon .button-icon .svg {
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

a.button.icon .button-icon .svg.plus-icon {
  width: 11px;
  height: 11px;
  background-image: url(@/assets/images/svg/icon-plus.svg);
}

a.button.icon .button-icon .svg.edit-icon {
  width: 12px;
  height: 12px;
  background-image: url(@/assets/images/svg/edit-icon.svg);
}

a.button.icon .button-icon .svg.varrow-icon {
  width: 6.12px;
  height: 12px;
  background-image: url(@/assets/images/svg/icon-h-arrow.svg);
}

a.button.icon .button-icon .svg.bin-icon {
  width: 9.33px;
  height: 12px;
  background-image: url(@/assets/images/svg/icon-bin.svg);
}

a.button.icon .button-icon .svg.preview-icon {
  width: 14.67px;
  height: 10px;
  background-image: url(@/assets/images/svg/icon-eyes.svg);
}

a.button.icon .button-icon .svg.check-icon {
  width: 11.73px;
  height: 8.94px;
  background-image: url(@/assets/images/svg/icon-check.svg);
}

a.button span {
  color: #FFFFFF;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

a.button .button-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

a.button.small .button-contents {
  gap: 2px;
}

a.text-button {
  display: inline-block;
}

a.text-button span {
  font-size: 1.4rem;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

a.text-button.blue span {
  color: #0062D4;
}

a.text-button .button-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 4px;
}

a.text-button .button-contents .buton-icon {
  position: relative;
  width: 16px;
  height: 16px;
}

a.text-button .button-contents .buton-icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

a.text-button .button-contents .buton-icon svg * {
  -webkit-transition: fill 0.5s, stroke 0.5s;
  transition: fill 0.5s, stroke 0.5s;
}

/* dim, alert, confirm */
.dim {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: -110;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.dim.show {
  opacity: 1;
}

.alert-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: -100;
}

.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 312px;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  padding: 40px 20px 20px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.alert-wrap.show .alert {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.alert .message {
  font-size: 1.6rem;
  color: #232324;
  text-align: center;
}

.alert .button {
  width: 100%;
  margin-top: 32px;
}

.confirm-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: -100;
}

.confirm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 312px;
  max-width: 312px;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  padding: 40px 20px 20px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.confirm-wrap.show .confirm {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.confirm .message {
  font-size: 1.6rem;
  color: #232324;
  text-align: center;
}

.confirm .confirm-btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  margin-top: 32px;
}

.confirm .confirm-btn-box .button {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

.prompt-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: -100;
}

.prompt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 552px;
  -webkit-transform: translateY(20%);
          transform: translateY(20%);
  padding: 40px;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transform: translateY(20%);
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.prompt-wrap.show .prompt {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.prompt .message {
  font-size: 1.6rem;
  color: #232324;
}

.prompt .prompt-btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  margin-top: 40px;
}

.prompt .prompt-btn-box .button {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
}

/* popup */
.popup-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  z-index: -100;
}

.popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: auto;
  width: 90vw;
  height: 80vh;
  max-width: 936px;
  max-height: 724px;
  padding: 32px;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  -webkit-transform: translateY(5%);
          transform: translateY(5%);
  opacity: 0;
  -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
}

.popup-wrap.show .popup {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.popup .popup-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 32px;
}

.popup .popup-header p {
  font-size: 2.4rem;
  font-weight: 700;
}

.popup .popup-header > a {
  width: 32px;
  height: 32px;
}

.popup .popup-header .flex-space {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.popup .popup-header > a svg {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.popup .popup-content {
  font-size: 1.2rem;
  overflow-y: auto;
}

.popup .popup-content table {
  margin-top: 16px;
  border-collapse: separate;
  border-spacing: 0 16px;
}

.popup .popup-content table * {
  font-size: 1.6rem;
}

.popup .popup-content table tr td:nth-child(1) {
  font-weight: 700;
}

.popup.popup-map .popup-content {
  height: 100%;
  overflow-y: hidden;
  padding-left: 64px;
  padding-right: 64px;
  padding-bottom: 60px;
}

.popup .popup-content .img-wrap {
  position: relative;
}

.popup .popup-content .img-wrap .layout-example-img {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-25%);
          transform: translateX(-25%);
}

.text-fadein {
  -webkit-animation: text-fadein 1s forwards;
          animation: text-fadein 1s forwards;
}

/* common class */
.overflow-hidden {
  overflow: hidden;
}

/* mouse cursor */
.cursor {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  mix-blend-mode: difference;
  will-change: transform;
  pointer-events: none;
  z-index: 99999;
  -webkit-transition-property: width, height, border-radius;
  transition-property: width, height, border-radius;
  -webkit-transition-duration: 0.3s, 0.3s, 0.4s;
          transition-duration: 0.3s, 0.3s, 0.4s;
  background-image: none;
  border: 1px solid black;
  -webkit-transform: translate(-9999px, -9999px);
          transform: translate(-9999px, -9999px);
}

.cursor.type-pointer {
  width: 60px;
  height: 60px;
  border: 2px solid #EBF4FF;
}

/* top button */
.top-btn {
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 58px;
  height: 58px;
  background-color: #FFFFFF;
  border: 1px solid #ffffff;
  border-radius: 8px;
  z-index: 500;
  -webkit-transition: -webkit-transform 0.3s, -webkit-background-color 0.3;
  transition: -webkit-transform 0.3s, -webkit-background-color 0.3;
  transition: transform 0.3s, background-color 0.3;
  transition: transform 0.3s, -webkit-transform 0.3s, background-color 0.3s, -webkit-background-color 0.3s;
  -webkit-transform: translateY(150%);
          transform: translateY(150%);
}

.top-btn.fix {
  position: absolute;
}

.top-btn.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.top-btn svg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: -webkit-fill 0.3s, -webkit-stroke 0.3s, border 0.3s;
  transition: -webkit-fill 0.3s, -webkit-stroke 0.3s, border 0.3s;
  transition: fill 0.3s, stroke 0.3s, border 0.3s;
  transition: fill 0.3s, -webkit-fill 0.3s, stroke 0.3s, -webkit-stroke 0.3s, border 0.3s, -webkit-border 0.3s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.container {
  position: relative;
  background-color: #FFFFFF;
}

.title-corver {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #1A1A1B;
  left:0;
  top:0;
  opacity:0;
}
.title-corver.show {
  opacity:1;
  z-index: 0;
}

.wrap {
  position: relative;
  margin: auto;
  max-width: 1128px;
}

.wrap.w1136 {
  max-width: 1136px;
}

.wrap .wrap-top-padding {
  padding-top: 164px;
}

.wrap .work-btn-overflow-hidden {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 165px;
  height: 164px;
  overflow: hidden;
}

.wrap .work-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 165px;
  height: 164px;
  border: 1px solid #232324;
  border-radius: 100px;
  overflow: hidden;
  opacity: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wrap .work-btn-overflow-hidden .work-btn {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wrap.w1136 .work-btn-overflow-hidden {
  right: 96px;
}

.wrap .work-btn .work-btn-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.wrap .work-btn .work-btn-mask::after {
  height: 100%;
  background-color: #1A1A1B;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  content: "";
  position: absolute;
  border-radius: 50%;
  left: -3rem;
  right: -3rem;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  will-change: transform;
  z-index: 0;
}

.wrap .work-btn:hover .work-btn-mask::after {
  top: 0;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.wrap .work-btn:hover p {
  color: #FFFFFF;
}

.wrap .work-btn p {
  display: inline-block;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.4rem;
  text-align: center;
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
  font-weight: 700;
  z-index: 1;
}

.wrap .title {
  position: relative;
}
.wrap .title.hide {
    z-index: -1;
}

.wrap .title .main-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Cormorant Garamond", serif;
  font-size: 14.4rem;
  line-height: 100%;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wrap .title .main-title span {
  font-size: 2.8rem;
  font-weight: 710;
}

.wrap .title .sub-title {
  margin-top: 48px;
  font-weight: 700;
  font-size: 3.2rem;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wrap .title .txt {
  width: 710px;
  margin-top: 16px;
  font-size: 2rem;
  color: #3A3C3E;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.wrap .title .about-txt {
  font-weight: 700;
  width: 700px;
  margin-top: 16px;
  font-size: 2.4rem;
  color: #FFFFFF;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wrap .title .title-container {
  position: fixed;
  top: 22vh;
}

.wrap .title .sub-title-container {
  position: fixed;
  top: 41vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 72px;
  height: 500px;
}

.wrap .title .sub-title-container .scroll-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 20px;
}

.wrap .title .sub-title-container .scroll-line .scroll-txt {
  margin-top: 8px;
  color: #FFFFFF;
  font-weight: 500;
  text-align: right;
  letter-spacing: 1em;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.wrap .title .sub-title-container .scroll-line .mouse-svg {
  width: 24px;
  height: 24px;
  position: relative;
}

.wrap .title .sub-title-container .scroll-line .mouse-svg svg {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.wrap .title .sub-title-container .scroll-line .line-box {
  width: 1px;
  height: 100%;
  overflow: hidden;
}

.wrap .title .sub-title-container .scroll-line .line-box .line {
  width: 1px;
  height: 100%;
  background: #FFFFFF;
  -webkit-animation: scroll-line-animation 3.5s ease infinite;
          animation: scroll-line-animation 3.5s ease infinite;
}

.wrap .title .sub-title-container .about-txts {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.wrap .title .sub-title-container .about-txts .sub-title {
  margin-top: 0;
  color: #FFFFFF;
  font-size: 4rem;
}

.wrap .title .sub-title-container .about-txts .txt-mini {
  font-weight: 300;
  margin-top: 32px;
  font-size: 1.6rem;
  color: #FFFFFF;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.wrap .title .sub-title-container .about-txts .txt-mini span {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  color: #FFFFFF;
}

.wrap .title .sub-title-container .about-txts .txt {
  color: #FFFFFF;
}

.wrap .keyword-container {
  position: relative;
  width: 100vw;
  margin-top: 85vh;
  margin-left: calc(-50vw + 50%);
  background-color: #FFFFFF;
  overflow: hidden;
}

.wrap .keyword-container .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 144px;
  margin: auto;
  max-width: 1136px;
  padding-top: 144px;
  padding-bottom: 144px;
}

.wrap .keyword-container .inner-container .keyword {
  width: 458px;
  z-index: 1;
}

.wrap .keyword-container .inner-container .keyword:nth-child(odd) {
  -ms-flex-item-align: end;
      align-self: end;
}

.wrap .keyword-container .inner-container .keyword .keyword-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #1A1A1B;
  z-index: 10;
}

.wrap .keyword-container .inner-container .keyword .keyword-content {
  margin-top: 12px;
  font-size: 2rem;
  color: #1A1A1B;
  z-index: 10;
}

.wrap .keyword-container .bg-txt-box {
  position: absolute;
  top: 240px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.wrap .keyword-container .bg-txt-box .bg-txt {
  font-family: "Cormorant Garamond";
  font-size: 16vw;
  line-height: 100%;
  color: #F4F5F5;
  opacity: 1;
  white-space: nowrap;
  margin-bottom: 32px;
  z-index: -1;
  -webkit-transition: color 1s, opacity 1s, -webkit-transform 1s;
  transition: color 1s, opacity 1s, -webkit-transform 1s;
  transition: transform 1s, color 1s, opacity 1s;
  transition: transform 1s, color 1s, opacity 1s, -webkit-transform 1s;
}

.wrap .keyword-container .bg-txt-box .bg-txt:nth-child(even) {
  position: relative;
  left: 20vw;
  z-index: 0;
}

.wrap .keyword-container .inner-container-section2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  max-width: 1136px;
  position: relative;
  padding-top: 24px;
  padding-left: 96px;
  padding-right: 96px;
}

.wrap .keyword-container .inner-container-section2 .keyword-works div {
  padding-top: 48px;
}

.wrap .keyword-container .inner-container-section2 .keyword-works div:nth-child(1) {
  padding-top: 0;
}

.wrap .keyword-container .inner-container-section2 .keyword-works span {
  display: inline-block;
  opacity: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  font-family: "Cormorant Garamond";
  font-size: 4.8rem;
  line-height: 100%;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section2 .keyword-works div:nth-child(5) span {
  color: #606365;
}

.wrap .keyword-container .inner-container-section2 .keyword-works div:nth-child(6) span {
  color: #C5C7C9;
}

.wrap .keyword-container .inner-container-section2 .keyword-works div:nth-child(7) span {
  color: #E8E8E9;
}

.wrap .keyword-container .inner-container-section2 .keyword-works div:nth-child(8) span {
  color: #F4F5F5;
}

.wrap .keyword-container .inner-container-section2 .keyword-works span.show {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.wrap .keyword-container .inner-container-section3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  max-width: 1136px;
  position: relative;
  padding-top: 120px;
  padding-left: 96px;
  padding-right: 96px;
}

.wrap .keyword-container .inner-container-section3 .works-scroll {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.wrap .keyword-container .inner-container-section3 .works-scroll div {
  width: 264px;
  height: 264px;
}

.wrap .keyword-container .inner-container-section3 .works-scroll div img {
  width: 100%;
  height: 264px;
}

.wrap .keyword-container .inner-container-section4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 80px;
  margin: auto;
  max-width: 1136px;
  position: relative;
  padding-top: 160px;
  padding-left: 96px;
  padding-right: 96px;
}

.wrap .keyword-container .inner-container-section4 .client-title .client-title-ko {
  font-size: 1.6rem;
  font-weight: 700;
  color: #3A3C3E;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section4 .client-title .client-title-ko.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.wrap .keyword-container .inner-container-section4 .client-title .client-title-en {
  font-family: "Cormorant Garamond";
  font-size: 6.4rem;
  line-height: 100%;
  margin-top: 16px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section4 .client-title .client-title-en.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.wrap .keyword-container .inner-container-section4 .clients {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  row-gap: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  justify-items: center;
  width: 100%;
}

.wrap .keyword-container .inner-container-section4 .clients div {
  max-width: 234px;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.wrap .keyword-container .inner-container-section4 .clients div.show {
  opacity: 1;
}

.wrap .keyword-container .inner-container-section4 .clients div img {
  max-width: 234px;
  width: 100%;
}

.wrap .keyword-container .inner-container-section5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 9999px;
  position: relative;
  left: -50%;
  margin-top: 160px;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #232324;
}

.wrap .keyword-container .inner-container-section5 .work-keywords {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  -webkit-animation: scroll-text 66s infinite linear;
          animation: scroll-text 66s infinite linear;
}

.wrap .keyword-container .inner-container-section5 .work-keywords:nth-child(2) {
  -webkit-animation: scroll-text-clone 66s infinite linear;
          animation: scroll-text-clone 66s infinite linear;
}

.wrap .keyword-container .inner-container-section5 .work-keywords span {
  font-size: 2.4rem;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wrap .keyword-container .inner-container-section6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: auto;
  max-width: 1136px;
  position: relative;
  padding-top: 160px;
}

.wrap .keyword-container .inner-container-section6 .location-title {
  font-family: "Cormorant Garamond";
  font-size: 13.6rem;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section6 .location-title.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.wrap .keyword-container .inner-container-section6 .location-info {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s;
  transition: -webkit-transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s;
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s;
  transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s, -webkit-transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.55s;
}

.wrap .keyword-container .inner-container-section6 .location-info.show {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.wrap .keyword-container .inner-container-section6 .location-info table {
  border-collapse: separate;
  border-spacing: 0 8px;
  font-size: 1.8rem;
  margin-top: -42px;
}

.wrap .keyword-container .inner-container-section6 .location-info table tr td:nth-child(1) {
  font-weight: 700;
}

.wrap .keyword-container .inner-container-section6 .location {
  position: relative;
  width: 100%;
  min-width: 564px;
  min-height: 141px;
  margin-top: 60px;
  overflow: hidden;
}

.wrap .keyword-container .inner-container-section6 .location .lines {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.wrap .keyword-container .inner-container-section6 .location .lines .line {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section6 .location .lines .line.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.wrap .keyword-container .inner-container-section6 .location .place p {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.2rem;
  color: #3A3C3E;
  z-index: 10;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.wrap .keyword-container .inner-container-section6 .location .place p.show {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.wrap .keyword-container .inner-container-section6 .location .place p::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #C5C7C9;
  border-radius: 50%;
}

.wrap .keyword-container .inner-container-section6 .location .place p:nth-child(1) {
  left: 12.145%;
  top: 21.925%;
}

.wrap .keyword-container .inner-container-section6 .location .place p:nth-child(2) {
  left: 32.269%;
  top: 31.785%;
}

.wrap .keyword-container .inner-container-section6 .location .place p:nth-child(3) {
  left: 56.737%;
  top: 39.882%;
}

.wrap .keyword-container .inner-container-section6 .location .place p:nth-child(4) {
  left: 61.702%;
  top: 90%;
}

.wrap .keyword-container .inner-container-section6 .location .place p:nth-child(5) {
  left: 74.379%;
  top: 69.642%;
}

.wrap .keyword-container .inner-container-section6 .location .place p:nth-child(6) {
  left: 91.755%;
  top: 24.603%;
}

.wrap .keyword-container .inner-container-section6 .location .marker {
  position: absolute;
  left: 48.581%;
  top: 36.785%;
  width: 32px;
  height: 32px;
  z-index: 20;
  -webkit-transform: translateY(-300px);
          transform: translateY(-300px);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section6 .location .marker.show {
  -webkit-animation: show-marker 1s forwards;
          animation: show-marker 1s forwards;
}

.wrap .keyword-container .inner-container-section6 .location .marker.fix-mode {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section6 .location .marker.fix-mode:hover {
  -webkit-transform: translateY(-10%) scale(1.4);
          transform: translateY(-10%) scale(1.4);
}

.wrap .keyword-container .inner-container-section6 .location .marker svg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.wrap .keyword-container .inner-container-section7 {
  position: relative;
  left: 50%;
  width: 100%;
  margin-top: 120px;
  padding-top: 160px;
  padding-bottom: 132.5px;
  background: #1A1A1B;
  min-width: 9999px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
}

.wrap .keyword-container .inner-container-section7 .last-comment {
  font-family: "Cormorant Garamond";
  font-size: 10.4rem;
  line-height: 100%;
  color: #3A3C3E;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.wrap .keyword-container .inner-container-section7 .last-comment.show {
  opacity: 1;
}

.wrap .keyword-container .inner-container-section7 .last-comment span {
  line-height: 0%;
}

.wrap .keyword-container .inner-container-section7 .last-small-comment {
  font-size: 2rem;
  color: #FFFFFF;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section7 .last-small-comment.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.wrap .keyword-container .inner-container-section7 .last-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 48px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 12px;
}

.wrap .keyword-container .inner-container-section7 .last-btns .move-container {
  -webkit-transform: translateY(30%);
          transform: translateY(30%);
  opacity: 0;
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, -webkit-transform 1s;
  transition: transform 1s, opacity 1s;
  transition: transform 1s, opacity 1s, -webkit-transform 1s;
}

.wrap .keyword-container .inner-container-section7 .last-btns .move-container.show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.wrap .works {
  position: relative;
  margin-top: 100px;
  padding-bottom: 234px;
}

.wrap .works .work {
  position: relative;
  width: 66.6666666667%;
  height: 100%;
  float: left;
  overflow: hidden;
}

.wrap .works .work::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.wrap .works .work.align-right {
    float: right;
}

.wrap .works .work .work-dim {
  position: absolute;
  width: 100%;
  height: 100%;
  will-change: transform;
  background-color: #CCCCCC;
  z-index:40;
  opacity:0;
}

.wrap .works .work .work-dim.slide-in-up {
  animation-duration:400ms;
  animation-name: slide-in-up;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-in-down {
  animation-duration:400ms;
  animation-name: slide-in-down;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-in-left {
  animation-duration:400ms;
  animation-name: slide-in-left;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-in-right {
  animation-duration:400ms;
  animation-name: slide-in-right;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-out-up {
  animation-duration:400ms;
  animation-name: slide-out-up;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-out-down {
  animation-duration:400ms;
  animation-name: slide-out-down;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-out-left {
  animation-duration:400ms;
  animation-name: slide-out-left;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}
.wrap .works .work .work-dim.slide-out-right {
  animation-duration:400ms;
  animation-name: slide-out-right;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
  opacity:1;
}

.wrap .works .work .work-container {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: padding 0.5s;
  transition: padding 0.5s;
  will-change: padding;
  opacity: 0;
}

.wrap .works .work.show .work-container {
  opacity: 1;
}

.wrap .works .work .work-container .corver {
  position: relative;
  left: 0;
  top: 0;
  padding: 60px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transition: border-radius 0.5s;
  transition: border-radius 0.5s;
}

.wrap .works .work .work-container .corver .inner-info {
  position: relative;
  width: 100%;
  height: 100%;
  width: 100%;
  z-index: 30;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.wrap .works .work .work-container .corver .inner-info * {
  color: #FFFFFF;
}

.wrap .works .work .work-container .corver .inner-info .work-title {
  position: absolute;
  top: 0;
  z-index: 15;
  opacity: 0;
}
.wrap .works .work .work-container .corver .inner-info .work-title .work-type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.wrap .works .work .work-container .corver .inner-info .work-title .work-type span {
  display: inline-block;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  text-align: center;
  font-size: 1.2rem;
}

.wrap .works .work .work-container .corver .inner-info .work-title .work-title-txt {
  margin-top: 16px;
  font-size: 2.8rem;
  font-weight: 700;
}

.wrap .works .work .work-container .corver .inner-info .work-info {
  position: absolute;
  bottom: 0;
  z-index: 15;
  opacity: 0;
}

.wrap .works .work .work-container .corver .inner-info .work-info table {
  border-collapse: collapse;
  /* border-spacing: 0 12px; */
}

.wrap .works .work .work-container .corver .inner-info .work-info table * {
  font-size: 1.6rem;
}

.wrap .works .work .work-container .corver .inner-info .work-info table td:nth-child(1) {
  vertical-align: top;
}

.wrap .works .work .work-container .corver .inner-info .work-info table tr td {
  padding-top: 6px;
  padding-bottom: 6px;
}
.wrap .works .work .work-container .corver .inner-info .work-info table tr:first-child td {
  padding-top: 0px;
  padding-bottom: 6px;
}
.wrap .works .work .work-container .corver .inner-info .work-info table tr:last-child td {
  padding-top: 6px;
  padding-bottom: 0px;
}

.wrap .works .work .work-container .corver .work-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.wrap .works .work .work-container .corver .work-bg img {
  width: 100%;
  height: 100%;
}

.wrap .works .work .work-container .corver .work-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  -webkit-transition: -webkit-backdrop-filter 0.5s;
  transition: -webkit-backdrop-filter 0.5s;
  transition: backdrop-filter 0.5s;
  transition: backdrop-filter 0.5s, -webkit-backdrop-filter 0.5s;
}

.works .work.m {
  width: 50%;
}

.works .work.m .work-container .corver {
  padding: 48px;
}

.works .work.s {
  width: 33.3333333333%;
}

.works .work.s .work-container .corver {
  padding: 32px;
}

.works .work.s .work-container .corver .inner-info .work-title .work-title-txt {
  margin-top: 12px;
  font-size: 2.4rem;
}

.works .work.s .work-container .corver .inner-info .work-info table {
  border-spacing: 0 4px;
}

.works .work.s .work-container .corver .inner-info .work-info table * {
  font-size: 1.4rem;
}
.works .work.s .work-container .corver .inner-info .work-info table tr td {
  padding-top: 2px;
  padding-bottom: 2px;
}
.works .work.s .work-container .corver .inner-info .work-info table tr:first-child td {
  padding-top: 0px;
  padding-bottom: 2px;
}
.works .work.s .work-container .corver .inner-info .work-info table tr:last-child td {
  padding-top: 2px;
  padding-bottom: 0px;
}

.wrap .works .work:hover .work-container {
  padding: 32px;
}

.works .work.m:hover .work-container {
  padding: 32px;
}

.works .work.s:hover .work-container {
  padding: 24px;
}

.wrap .works .work:hover .work-container .corver {
  border-radius: 16px;
}

.wrap .works .work:hover .work-container .corver .work-bg {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.works .work.m:hover .work-container .corver .work-bg {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.works .work.s:hover .work-container .corver .work-bg {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.wrap .works .work:hover .work-container .corver .inner-info .work-title {
  opacity: 1;
}

.wrap .works .work:hover .work-container .corver .inner-info .work-info {
  opacity: 1;
}

.wrap .works .work:hover .work-container .corver .work-blur {
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
}

.wrap .works .clear-both {
  clear: both;
}

.wrap .works .work.hide {
    display: none;
}

.wrap .works .work .work-last {
  position: relative;
  padding: 60px;
}

.wrap .works .work .work-last p {
  font-family: "Cormorant Garamond";
  font-size: 4rem;
  line-height: 150%;
  color: #C5C7C9;
}

.wrap .works .work .work-last .last-top-btn {
  position: relative;
  display: block;
  margin-top: 60px;
  width: 58px;
  height: 58px;
  border: 1px solid #1A1A1B;
  border-radius: 50%;
  -webkit-transition: -webkit-background-color 0.3;
  transition: -webkit-background-color 0.3;
  transition: background-color 0.3;
  transition: background-color 0.3s, -webkit-background-color 0.3s;
}

.wrap .works .work .work-last .last-top-btn svg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.wrap .works .work .work-last .last-top-btn svg path {
  -webkit-transition: -webkit-fill 0.3s, -webkit-stroke 0.3s, border 0.3s;
  transition: -webkit-fill 0.3s, -webkit-stroke 0.3s, border 0.3s;
  transition: fill 0.3s, stroke 0.3s, border 0.3s;
  transition: fill 0.3s, -webkit-fill 0.3s, stroke 0.3s, -webkit-stroke 0.3s, border 0.3s, -webkit-border 0.3s;
}

.wrap .works .work.last::after {
  display: none;
}

/* contact-us */
.form-tab {
  --background: rgba(239, 239, 240, 1);
  background: var(--background);
  border-radius: 12px;
  margin-top: 80px;
  padding: 8px;
  border: none;
  outline: none;
  display: grid;
  gap:8px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.form-tab .option {
  position: relative;
}

.form-tab .option label {
  position: relative;
  display: block;
  text-align: center;
  padding: 12px 6vmin;
  background: rgba(255, 255, 255, 0);
}

/* .form-tab .option label::before,
.form-tab .option label::after {
  content: "";
  width: 1px;
  background: rgba(142, 142, 147, 0.15);
  position: absolute;
  top: 18%;
  bottom: 18%;
  border-radius: 12px;
} */

.form-tab .option label::before {
  left: 0;
  -webkit-transform: translateX(-0.5px);
          transform: translateX(-0.5px);
}

.form-tab .option label::after {
  right: 0;
  -webkit-transform: translateX(0.5px);
          transform: translateX(0.5px);
}

.form-tab .option:first-of-type {
  grid-column: 1;
  grid-row: 1;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.form-tab .option:first-of-type label::before {
  opacity: 0;
}

.form-tab .option:last-of-type label::after {
  opacity: 0;
}

.form-tab .option input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  opacity: 0;
}

.form-tab .selection {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  will-change: transform;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.form-tab .option label {
  -webkit-transition: -webkit-background-color 0.2s ease;
  transition: -webkit-background-color 0.2s ease;
  transition: background-color 0.2s ease;
  transition: background-color 0.2s ease, -webkit-background-color 0.2s ease;
}

.form-tab .option label:hover {
    background-color: #E8E8E9;
    border-radius: 8px;
}

.form-tab .option label span {
  display: block;
  position: relative;
  z-index: 2;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
  will-change: transform;
  padding: 5px;
  font-weight: 700;
  font-size: 1.8rem;
}

.form-tab .option input:checked + label::before,
.form-tab .option input:checked + label::after {
  background: var(--background);
  z-index: 1;
}

.input-box {
  margin-top: 60px;
}

.input-box .title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 2rem;
}

.input-box .input-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}

.input-box .input-container > div {
  -ms-flex-preferred-size: 50%;
      flex-basis: 50%;
}

.input-box .input-container p {
  font-weight: 700;
  font-size: 1.4rem;
  color: #606365;
}

.input-box .input-container .border {
  width: 100%;
  height: 1px;
  background-color: #C5C7C9;
}

.input-box .input-container .border > div {
  width: 0%;
  height: 1px;
  background-color: #232324;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}

.input-box .input-container input:focus + .border > div {
  width: 100%;
}

/* input - checkbox */
.input-box .checkbox-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
}

.input-box .checkbox {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
}

.input-box .checkbox input[type=checkbox] {
  display: none;
}

.input-box .checkbox input[type=checkbox] + label {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 1.6rem;
  text-align: center;
  display: inline-block;
  -webkit-transition: background-color 0.5s, border 0.5s;
  transition: background-color 0.5s, border 0.5s;
  border: 1px solid #C5C7C9;
  border-radius: 4px;
}

.input-box .checkbox input[type=checkbox] + label span {
  -webkit-transition: color 0.5s;
  transition: color 0.5s;
}

.input-box .checkbox input[type=checkbox]:checked + label {
  background-color: #232324;
  border: 1px solid #232324;
}

.input-box .checkbox input[type=checkbox]:checked + label span {
  color: #FFFFFF;
}

.input-box .normal-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.input-box .normal-checkbox input[type=checkbox] {
  display: none;
}

.input-box .normal-checkbox label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.input-box .normal-checkbox label .check-icon {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #C5C7C9;
  border-radius: 4px;
}

.input-box .normal-checkbox label .check-icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.input-box .normal-checkbox label span {
  font-size: 1.6rem;
  margin-left: 10px;
}

.input-box .normal-checkbox input[type=checkbox]:checked + label .check-icon {
  border: 2px solid #1A1A1B;
  background: #1A1A1B;
}

.input-box .normal-checkbox a {
  margin-left: 8px;
  font-size: 1.4rem;
  color: #606365;
  -webkit-text-decoration: underline #606365;
          text-decoration: underline #606365;
}

/* input - text */
.input-box .input-container .text input[type=text] {
  font-size: 1.6rem;
  width: 100%;
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #232324;
  outline: none;
}

.input-box .input-container .text input[type=text]::-webkit-input-placeholder {
  color: #C5C7C9;
}

.input-box .input-container .text input[type=text]::-moz-placeholder {
  color: #C5C7C9;
}

.input-box .input-container .text input[type=text]:-ms-input-placeholder {
  color: #C5C7C9;
}

.input-box .input-container .text input[type=text]::-ms-input-placeholder {
  color: #C5C7C9;
}

.input-box .input-container .text input[type=text]::placeholder {
  color: #C5C7C9;
}

/* input - select */
.input-box .input-container .select-box {
  font-size: 1.6rem;
  position: relative;
}

.input-box .input-container .select-box .options {
  position: absolute;
  width: 100%;
  border: 1px solid #E8E8E9;
  border-radius: 8px;
  margin-top: 2px;
  -webkit-transition: opacity 0.3s, z-index 0.3s;
  transition: opacity 0.3s, z-index 0.3s;
  opacity: 0;
  background: #FFFFFF;
  z-index: -1;
}

.input-box .input-container .select-box .options.show {
  opacity: 1;
  z-index: 10;
}

.input-box .input-container .select-box .options > div {
  padding: 12px;
  font-size: 1.4rem;
}

.input-box .input-container .select-box .options > div.select-choose {
  background: #EBF4FF;
  color: #0062D4;
}

.input-box .input-container .select-box .select-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.input-box .input-container .select-box .select-value span {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  color: #C5C7C9;
}

.input-box .input-container .select-box .select-value span.selected {
  color: #232324;
}

.input-box .input-container .select-box .select-value svg {
  width: 24px;
}

/* input - textarea */
.input-box .input-container .textarea textarea {
  width: 100%;
  height: 170px;
  margin-top: 12px;
  padding: 12px;
  font-size: 1.6rem;
  border: 1px solid #C5C7C9;
  border-radius: 4px;
  resize: none;
}

.input-box .input-container .textarea textarea::-webkit-input-placeholder {
  font-size: 1.6rem;
  color: #C5C7C9;
}

.input-box .input-container .textarea textarea::-moz-placeholder {
  font-size: 1.6rem;
  color: #C5C7C9;
}

.input-box .input-container .textarea textarea:-ms-input-placeholder {
  font-size: 1.6rem;
  color: #C5C7C9;
}

.input-box .input-container .textarea textarea::-ms-input-placeholder {
  font-size: 1.6rem;
  color: #C5C7C9;
}

.input-box .input-container .textarea textarea::placeholder {
  font-size: 1.6rem;
  color: #C5C7C9;
}

.input-box .input-container .textarea p span {
  font-weight: 700;
  font-size: 1.4rem;
  color: #606365;
}

.input-box .input-container .textarea p span.textarea-count {
  float: right;
  font-weight: 300;
  padding-right: 1px;
}

.input-box .input-container .textarea p span::after {
  content: "";
  clear: both;
}

/* input - file*/
.input-box .input-container .file .file-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.input-box .input-container .file .file-value span {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  font-size: 1.6rem;
  color: #C5C7C9;
}

.input-box .input-container .file .file-value span.selected {
  color: #232324;
}

.input-box .input-container .file .file-value svg {
  width: 24px;
}

/* forms */
.forms {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 152px;
}

.forms form {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  -webkit-transition: -webkit-transform 0.4s, -webkit-opacity 0.4s;
  transition: -webkit-transform 0.4s, -webkit-opacity 0.4s;
  transition: transform 0.4s, opacity 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s, -webkit-opacity 0.4s, opacity 0.4s;
}

.forms form:nth-child(1) {
  display: block;
  opacity: 1;
}
.forms form.none {
  display: none;
}

.forms form:nth-child(1).show {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  position: relative;
  opacity: 1;
}

.forms form:nth-child(1).hide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  position: absolute;
  opacity: 0;
}

.forms form:nth-child(2) {
  position: absolute;
  left: 100%;
  opacity: 0;
}

.forms form:nth-child(2).show {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  position: relative;
  opacity: 1;
}

.forms form:nth-child(2).hide {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  position: absolute;
  opacity: 0;
}

/* about */
.video-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  overflow: hidden;
  background: #000000;
  z-index: -1;
}

.video-bg video {
  position: absolute;
  width: 110%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.5;
}

/* white mode - contents */
.white .wrap .title .main-title {
  color: #FFFFFF;
}

.white .wrap .keyword-container {
  background-color: #1A1A1B;
}

.white .wrap .keyword-container .inner-container .keyword .keyword-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #FFFFFF;
}

.white .wrap .keyword-container .inner-container .keyword .keyword-content {
  margin-top: 12px;
  color: #FFFFFF;
}

.white .wrap .keyword-container .bg-txt-box .bg-txt {
  color: rgba(255, 255, 255, 0.05);
}

/* device setting */
@media (max-width: 1248px) {
  .popup.popup-map .popup-content {
    padding: 0px;
  }
  /* contact-us */
  .wrap {
    margin-left: 48px;
    margin-right: 48px;
  }
  /* about */
  .wrap .keyword-container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wrap .title .sub-title-container .about-txts {
    padding-right: 48px;
  }
  .wrap .keyword-container .inner-container-section4 .clients {
    /* grid-template-columns: repeat(3, 1fr); */
  }
  /* our-work */
  .wrap .works .work .work-last p {
    font-size: 3rem;
  }
  .wrap .works .work.s:hover .work-container .corver {
    padding: 28px;
  }
}
@media (max-width: 1100px) {
  /* our-work */
  .wrap .works {
    margin-top: 60px;
    padding-bottom: 111px;
  }
  .wrap .works .work {
    width: 100%;
  }
  .wrap .works .work.m {
    width: 100%;
  }
  .wrap .works .work.s {
    width: 100%;
  }
  .wrap .works .work.last {
    display: none;
  }
  .wrap .works .work .work-container .corver {
    padding: 32px;
  }
  .wrap .works .work .work-container .corver .inner-info .work-title .work-type {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .wrap .works .work .work-container .corver .inner-info .work-title .work-title-txt {
    margin-top: 16px;
    line-height: 140%;
    font-size: 2rem;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .wrap .works .work .work-container .corver .inner-info .work-info {
    overflow: hidden;
  }
  .wrap .works .work .work-container .corver .inner-info .work-info table {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  .wrap .works .work .work-container .corver .inner-info .work-info table * {
    font-size: 1.4rem;
  }
  .wrap .works .work .work-container .corver .inner-info .work-info table tr td {padding-top:4px;padding-bottom:4px;}
  .wrap .works .work .work-container .corver .inner-info .work-info:first-child table tr td {padding-top:0px;padding-bottom:4px;}
  .wrap .works .work .work-container .corver .inner-info .work-info:last-child table tr td {padding-top:4px;padding-bottom:0px;}
  .wrap .works .work:hover .work-container {
    padding: 16px;
  }
  .wrap .works .work:hover .work-container .corver .inner-info .work-title .work-type {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
  }
  .wrap .works .work:hover .work-container .corver .inner-info .work-title .work-title-txt {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transition-delay: 0.6s;
            transition-delay: 0.6s;
  }
  .wrap .works .work:hover .work-container .corver .inner-info .work-info table {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-transition-delay: 0.9s;
            transition-delay: 0.9s;
  }
  .wrap .works .work:hover .work-container .corver .inner-info .work-info table colgroup col:first-child {
    width: 72px;
  }
  .wrap .works .work:hover .work-container .corver .work-bg {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@media (max-width: 948px) {
  .remove-w948 {
    display: none;
  }
  /* about */
  .wrap .keyword-container .inner-container-section4 .clients {
    grid-template-columns: repeat(2, 1fr);
  }
  /* our-work */
  .wrap .works .work .work-last p {
    font-size: 2rem;
  }
}
@media (max-width: 768px) {
  /* contact-us */
  .wrap {
    margin-left: 24px;
    margin-right: 24px;
  }
  .wrap .wrap-top-padding {
    padding-top: 140px;
  }
  .wrap .title .main-title {
    font-size: 6.4rem;
  }
  .wrap .title .sub-title {
    margin-top: 28px;
    font-weight: 700;
    font-size: 1.8rem;
  }
  .wrap .title .txt {
    width: 85vw;
    margin-top: 12px;
    font-size: 1.4rem;
  }
  .wrap .title .about-txt {
    width: 85vw;
    margin-top: 12px;
    font-size: 1.4rem;
  }
  .form-tab {
    margin-top: 40px;
    padding: 2px;
  }
  .form-tab .selection {
    -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
  }
  .form-tab .option label span {
    font-size: 1.4rem;
    padding: 0;
  }
  .input-box {
    margin-top: 40px;
  }
  .input-box .input-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 24px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .input-box .input-container > div {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
  .input-box .title {
    font-size: 1.7rem;
  }
  .input-box .checkbox-container {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 9px;
    -ms-flex-line-pack: center;
        align-content: center;
  }
  .input-box .checkbox-container.flex-blank::after {
    content: "";
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 40%;
            flex: 1 0 40%;
  }
  .input-box .checkbox-container .checkbox {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 40%;
            flex: 1 0 40%;
  }
  /* about */
  .wrap .work-btn {
    display: none;
  }
  .wrap .keyword-container {
    padding-left: 24px;
    padding-right: 24px;
  }
  .wrap .title .main-title {
    font-size: 6.4rem;
  }
  .wrap .title .title-container {
    top: 16vh;
  }
  .wrap .title .sub-title-container {
    height: auto;
    top: 28vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 36px;
  }
  .wrap .title .sub-title-container .scroll-line {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    height: 500px;
  }
  .wrap .title .sub-title-container .scroll-line .scroll-txt {
    display: none;
  }
  .wrap .title .sub-title-container .scroll-line .mouse-svg {
    display: none;
  }
  .wrap .title .sub-title-container .about-txts {
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding-right: 24px;
  }
  .wrap .title .sub-title-container .about-txts .sub-title {
    font-size: 2.8rem;
  }
  .wrap .title .sub-title-container .about-txts .txt-mini {
    margin-top: 16px;
    font-size: 1.4rem;
    width: 85vw;
  }
  .wrap .title .sub-title-container .about-txts .txt {
    font-size: 1.8rem;
    width: 80vw;
  }
  .wrap .keyword-container .bg-txt-box .bg-txt {
    font-size: 16rem;
  }
  .wrap .keyword-container .inner-container {
    gap: 120px;
    padding-top: 120px;
    padding-bottom: 80px;
  }
  .wrap .keyword-container .inner-container .keyword {
    width: 264px;
  }
  .wrap .keyword-container .inner-container .keyword .keyword-title {
    font-size: 1.6rem;
  }
  .wrap .keyword-container .inner-container .keyword .keyword-content {
    font-size: 1.4rem;
  }
  .wrap .keyword-container .inner-container-section2 {
    padding-left: 0;
  }
  .wrap .keyword-container .inner-container-section2 .keyword-works span {
    font-size: 2.4rem;
  }
  .wrap .keyword-container .inner-container-section2 .keyword-works div {
    padding-top: 24px;
  }
  .wrap .keyword-container .inner-container-section3 {
    padding-top: 80px;
    padding-left: 0;
    padding-right: 0;
  }
  .wrap .keyword-container .inner-container-section3 .works-scroll div {
    width: 136px;
    height: 136px;
  }
  .wrap .keyword-container .inner-container-section3 .works-scroll div img {
    width: 100%;
    height: 136px;
  }
  .wrap .keyword-container .inner-container-section4 {
    padding-top: 120px;
    padding-left: 0;
    padding-right: 0;
    gap: 40px;
  }
  .wrap .keyword-container .inner-container-section4 .clients div img {
    /* width: 156px; */
  }
  .wrap .keyword-container .inner-container-section4 .client-title .client-title-ko {
    font-size: 1.6rem;
  }
  .wrap .keyword-container .inner-container-section4 .client-title .client-title-en {
    font-size: 4.8rem;
  }
  .wrap .keyword-container .inner-container-section5 {
    margin-top: 80px;
  }
  .wrap .keyword-container .inner-container-section5 .work-keywords span {
    font-size: 1.2rem;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .wrap .keyword-container .inner-container-section6 {
    padding-top: 80px;
  }
  .wrap .keyword-container .inner-container-section6 .location-title {
    font-size: 6.4rem;
  }
  .wrap .keyword-container .inner-container-section6 .location {
    margin-top: 32px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .wrap .keyword-container .inner-container-section6 .location-info table {
    margin-top: 32px;
    font-size: 1.4rem;
  }
  .wrap .keyword-container .inner-container-section6 .location-info table colgroup col:first-child {
    width: 52px;
  }
  .wrap .keyword-container .inner-container-section6 .location .marker {
    width: 24px;
    height: 24px;
  }
  .wrap .keyword-container .inner-container-section6 .location .place p {
    font-size: 1rem;
  }
  .wrap .keyword-container .inner-container-section7 {
    margin-top: 80px;
    padding-top: 120px;
  }
  .wrap .keyword-container .inner-container-section7 .last-comment {
    font-size: 4.8rem;
  }
  .wrap .keyword-container .inner-container-section7 .last-comment span {
    display: block;
  }
  .wrap .keyword-container .inner-container-section7 .last-small-comment {
    font-size: 1.8rem;
  }
  .wrap .keyword-container .inner-container-section7 .last-btns {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .wrap .keyword-container .inner-container-section7 .last-btns a {
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 1.4rem;
    width: 66vw;
  }
}
/* key frames */
@-webkit-keyframes slide-in-up {
  0% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-in-up {
  0% {
    transform: translateY(101%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slide-in-down {
  0% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-in-down {
  0% {
    transform: translateY(-101%);
  }
  100% {
    transform: translateY(0);
  }
}
@-webkit-keyframes slide-in-left {
  0% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-in-left {
  0% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-in-right {
  0% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-in-right {
  0% {
    transform: translateX(-101%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-out-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-101%);
  }
}
@keyframes slide-out-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-101%);
  }
}
@-webkit-keyframes slide-out-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(101%);
  }
}
@keyframes slide-out-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(101%);
  }
}
@-webkit-keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-101%);
  }
}
@keyframes slide-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-101%);
  }
}
@-webkit-keyframes slide-out-right {  
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(101%);
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
    display: block;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes text-fadein {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes text-fadein {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes scroll-line-animation {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@keyframes scroll-line-animation {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
}
@-webkit-keyframes scroll-text {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  50% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50.001% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes scroll-text {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  50% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  50.001% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes scroll-text-clone {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}
@keyframes scroll-text-clone {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-200%);
            transform: translateX(-200%);
  }
}
@-webkit-keyframes show-marker {
  0% {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes show-marker {
  0% {
    -webkit-transform: translateY(-300px);
            transform: translateY(-300px);
  }
  50% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
/* 페이지 전환 */
.page-mask {position:fixed;left:0;top:0;width:100vw;height:100vh;background-color:#28292A;z-index:20000;}
.page-mask-enter-from {transform: translateY(100%);}
.page-mask-enter-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .75s;}
.page-mask-enter-to {transform: translateY(0);}
.page-mask-leave-from {transform: translateY(0%);}
.page-mask-leave-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .75s;}
.page-mask-leave-to {transform: translateY(-100%);}
.page-mask-text {position:fixed;left:10.83333%;top:50%;transform:translateY(-50%);z-index:20001;}
.page-mask-text .page-title {font-family:'Cormorant Garamond';font-size:13.6rem;line-height:100%;color:#ffffff;}
.page-mask-title-enter-from {transform: translateY(100%);}
.page-mask-title-enter-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .5s;transition-delay: .25s;}
.page-mask-title-enter-to {transform: translateY(0);}
.page-mask-title-leave-from {transform: translateY(0%);}
.page-mask-title-leave-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .5s;}
.page-mask-title-leave-to {transform: translateY(-100%);}
.page-mask-text .page-title-underline {width:100%;height:2px;background-color:#ffffff;}
.page-mask-title-underline-enter-from {transform: translateX(-100%);}
.page-mask-title-underline-enter-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .3s;transition-delay: .4s;}
.page-mask-title-underline-enter-to {transform: translateY(0);}
.page-mask-title-underline-leave-from {transform: translateY(0%);}
.page-mask-title-underline-leave-active {transition: transform cubic-bezier(0.54,-0.02, 0.39, 0.99) .3s;}
.page-mask-title-underline-leave-to {transform: translateX(100%);}

@media (max-width: 768px) {
    .page-mask-text .page-title {font-size: 6.4rem;}
}

@media (hover: hover) {
    a.button:hover {
        background-color: #1A1A1B;
    }
    a.button.button-blue:hover {
        background-color: #003674;
        border: 1px solid #003674;
    }

    a.button.button-blue:hover span {
        color: #FFFFFF;
    }

    a.button.button-blue:hover svg * {
        fill: #FFFFFF;
        stroke: #FFFFFF;
    }
    a.button.button-white:hover {
        background-color: #1A1A1B;
        border: 1px solid #ffffff;
    }

    a.button.button-white:hover span {
        color: #FFFFFF;
    }

    a.button.button-white:hover svg * {
        fill: #FFFFFF;
        stroke: #FFFFFF;
    }
    

    a.button.button-gray:hover {
    background-color: #1A1A1B;
    border: 1px solid #1A1A1B;
    }

    a.button.button-gray:hover span {
    color: #FFFFFF;
    }

    a.button.button-gray:hover svg * {
    fill: #FFFFFF;
    stroke: #FFFFFF;
    }

    a.button.button-non-bg:hover {
    background-color: #FFFFFF;
    }

    a.button.button-non-bg:hover span {
    color: #28292A;
    }

    a.button.button-non-bg:hover svg * {
    fill: #28292A;
    stroke: #28292A;
    }

    a.text-button.blue:hover span {
    color: #003674;
    }

    a.text-button.blue:hover .buton-icon svg {
    fill: #003674;
    stroke: #003674;
    }

    .input-box .checkbox input[type=checkbox]:hover + label {
    background-color: #232324;
    border: 1px solid #232324;
    }

    .input-box .checkbox input[type=checkbox]:hover + label span {
    color: #FFFFFF;
    }

    .input-box .input-container .select-box .options > div:hover {
    background: #EBF4FF;
    }

    .wrap .works .work .work-last .last-top-btn:hover {
        background-color: #1a1a1b;
    }
    .wrap .works .work .work-last .last-top-btn:hover svg path {
        fill: #ffffff;
        stroke: #ffffff;
    }
}


/** SCROLL BASIC */
/* html.has-scroll-smooth {
  overflow: hidden; }

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.has-scroll-smooth body {
  overflow: hidden; }

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh; }

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap; }

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%; }

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; }
  .c-scrollbar:hover {
    transform: scaleX(1.45); }
  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; }
  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); }
    [data-scroll-direction="horizontal"] .c-scrollbar:hover {
      transform: scaleY(1.3); }

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab; }
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0; } */
</style>